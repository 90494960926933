import React, { useEffect, useState } from "react";

import styles from "./LoginForm.module.css";
import { postLogin } from "../../api/Api";

export const LoginForm = (props) => {
    //props:
    //
    //putToken - function for sucessfull login
    // ----------------------------------------------------- STATE AND HOOKS

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    // ----------------------------------------------------- PRE-RENDER

    function handleSubmit() {
        fetchToken();
    }

    const handleKeyDown = (e) => {
        //sends credentials to database if 'enter' is pressed in password box
        if (e.keyCode === 13) {
            fetchToken();
        }
    }

    async function fetchToken() {

        const credentials = {
            username: username,
            password: password
        }

        await postLogin(credentials)
            .then((generatedToken) => {
                props.putToken(generatedToken);
            })
            .catch((error) => {
                console.log("failed to log in");
                console.log(error);
            })
    }



    // ----------------------------------------------------- RENDER
    return (
        <section>
            <h1 className={styles.sectionHeader}>Log In</h1>
            <div className={styles.form}>
                <div className={styles.labelHeader}>
                    <h1>Username</h1>
                    <input  type="text" 
                            className={styles.inputBox} 
                            onChange={event => setUsername(event.target.value)}/>
                </div>
                <div className={styles.labelHeader} >
                    <h1>Password</h1>
                    <input  type="password" 
                            className={styles.inputBox} 
                            onChange={event => setPassword(event.target.value)} 
                            onKeyDown={handleKeyDown}/>
                </div>
                <div>
                    <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </section>
    );
}