import React, { useState, useEffect } from "react";
import styles from "./RecentlyAdded.module.css";

import { getBookPage, getBookPageAllAccess, getTotalItems } from '../../api/Api';
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";

export const RecentlyAdded = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const [books, setBooks] = useState([]);
    const [bookObjects, setBookObjects] = useState([]);

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchBooks();
    }, []);

    // ----------------------------------------------------- PRE-RENDER
    async function fetchBooks() {
        // gets book page from back

        var pageSize = 3;
        if (window.screen.width < 500) pageSize = 2


        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBookPageAllAccess(1, pageSize, "recent", token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([]); // invokes empty shelf message
                    console.log(error)
                });
        } else {
            // limited access
            await getBookPage(1, pageSize, "recent", token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([]); // invokes empty shelf message
                    console.log(error)
                });
        }
    }

    function makeBookItems(foundBooks) {
        // makes book items
        if (foundBooks.length > 0) {
            let tempList = []
            for (let i = 0; i<foundBooks.length; i++) {
                tempList.push(
                    <div key={foundBooks[i].isbn}><TBRbook bookItem={foundBooks[i]}/></div>
                )
            }
            setBookObjects(tempList);
        }
        else {
            setBookObjects(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                {bookObjects}
            </div>
        </section>
    )
}