import React, { useState, useEffect } from "react";
import { useBook, useBookUpdate } from "../../contexts/BookContext";

import { Link } from "react-router-dom";

import styles from "./BookBanner.module.css";

import { ReviewBox } from "../ReviewBox/ReviewBox";
import { FieldsBox } from "../FieldsBox/FieldsBox";
import { EditionBox } from "../EditionBox/EditionBox";

import Clamp from 'react-multiline-clamp';
import { addToLibrary, patchBook } from "../../api/Api";
import { Journal } from "../Journal/Journal";
import { Cover } from "../Cover/Cover";
import { Author } from "../Author/Author";
import { Recommendations } from "../Recommendations/Recommendations";

export const BookBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const bookItem = useBook();
    const bookUpdater = useBookUpdate();

    let token = localStorage.getItem("token");

    const [formattedFull, setformattedFullDescripton] = useState();
    const [favorited, setFavorited] = useState();

    const [firstWordsOfTitle, setFirstWordsOfTitle] = useState("");
    const [lastWordOfTitle, setLastWordOfTitle] = useState("");

    const [authors, setAuthors] = useState();

    const [isLoaded, setIsLoaded] = useState(false);
    
    let seriesPath = "/series/" + bookItem.series?.id;

    useEffect(() => { 
        // shows read more if over 100 characters
        if (bookItem && bookItem.description) {
            setformattedFullDescripton(formatDescription(bookItem.description));
        }
    }, [bookItem.description])

    useEffect(() => { 
        // sets favorited when bookItem arrives
        setFavorited(bookItem.favorited)
        if (bookItem && bookItem.title) { processTitle(); }

        // checks to see if book data has loaded yet
        if (Object.keys(bookItem).length !== 0) {
            setIsLoaded(true);
        }

        // if (bookItem && bookItem.authors) formatAuthors();

    }, [bookItem]);

    useEffect(() => { // scrolls to top on first render
        window.scroll(0, 0);
    }, [])



    // ----------------------------------------------------- PRE-RENDER

    function formatDescription(description) {
        //adding white space to the description
        if (description === null) return <div key="no description">No available description</div>;
        let formattedDescripton = []
        let descriptionPeices = description.split('\n'); //splits description string by newlines
        if (descriptionPeices.length === 1) { //no new lines
            return description;
        } else {
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { //if empty it means there was a blank line
                    formattedDescripton.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    formattedDescripton.push(<div key={i}>{descriptionPeices[i]}</div>) //adds string segment to array
                }
            }
            return (formattedDescripton);
        }
    }

    // function formatAuthors() {
    //     // creates author information block

    //     switch (bookItem.authors.length) {
    //         case 0:
    //             setAuthors(null);
    //             break;
    //         case 1 :
    //             setAuthors(<Author authorsList={bookItem.authors} size={window.screen.width > 500 ? "L" : "S"}/>)
    //             break;
    //         case 2:
    //             setAuthors(
    //                 <div className={styles.authorContainer}>
    //                     <div className={styles.authorGroup}>
    //                         <Author authorsList={bookItem.authors} size="L" />
    //                         <div className={styles.and}>&</div>
    //                     </div>
    //                     <Author authorsList={bookItem.authors} size="L" />
    //                 </div>
    //             )
    //             break;
    //         case 3:
    //             setAuthors(
    //                 <div className={styles.authorContainer}>
    //                     <div className={styles.authorGroup}>
    //                         <Author authorsList={bookItem.authors} size="L" />
    //                         <div className={styles.comma}>,</div>
    //                     </div>
    //                     <div className={styles.authorGroup}>
    //                         <Author authorsList={bookItem.authors} size="L" />
    //                         <div className={styles.and}>&</div>
    //                     </div>
    //                     <Author authorsList={bookItem.authors} size="L" />
    //                 </div>
    //             )
    //             break;
    //         default:
    //             setAuthors(
    //                 <div className={styles.authorContainer}>
    //                     <div>There are too many, honestly</div>
    //                 </div>
    //             )
    //     }
        
    // }

    function handleClick() {
        saveChanges();
    }

    function processTitle() {
        //splits title into two peices and adds star to second to prevent weird line wrapping
        let words = bookItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "
        lastWord = [lastWord, <img key="star" className={bookItem.favorited ? styles.starIconFilled : styles.starIconEmpty} alt="Favorite star" />];

        setFirstWordsOfTitle(firstWords);
        setLastWordOfTitle(lastWord);
    }

    async function saveChanges() {
        //sets book as favorite or unfavorite
        await patchBook(bookItem.userbookID, { favorited: !favorited }, token)
            .then((book) => {
                setFavorited(!favorited);
                bookUpdater(book);
            })
            .catch((error) => {
                console.log("failed to change status");
                console.log(error)
            })
    }

    async function handleAddToLibrary() {
        // adds book to user's library
        await addToLibrary(bookItem.bookID, token)
            .then((userbook) => {
                bookUpdater(userbook);
            })
            .catch((error) => {
                console.log("failed to add book to library")
                console.log(error)
            })
    }

    // ----------------------------------------------------- RENDER


    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.rowOne}>
                    <div className={styles.bookInfoContainer}>
                        {bookItem.userbookID ?
                            <div className={styles.coverAndTriangle}>
                                <div className={styles.triangle} />
                                <div className={styles.bookCover}>
                                    <div className={styles.triangleBookCover} />
                                    <Cover bookItem={bookItem} size={window.screen.width > 500 ? "XL" : "M"} loc="bookBanner"/> 
                                </div>
                            </div>
                            :
                            <div className={styles.coverImageAndButton}>
                                <div className={styles.bookCover}>
                                    <Cover bookItem={bookItem} size={window.screen.width > 500 ? "XL" : "M"} loc="bookBanner"/> 
                                </div>
                                <button className={styles.addToLibraryButton} onClick={handleAddToLibrary}>Add To Library</button>
                            </div>
                        }
                        <div className={styles.infoAndDescription}>
                            <div className={styles.bookInfo}>
                                <div className={styles.titleAndSeries}>
                                    <div className={styles.titleContainer}>
                                        <button className={styles.favoriteButton} onClick={handleClick}>
                                            <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                                            <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                                        </button>

                                    </div>
                                    {bookItem.series != null ?
                                        <div>
                                            <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{bookItem.series?.name} #{bookItem.seriesNum}</h3></Link>
                                        </div> :
                                        null}
                                </div>
                                <Author authorsList={bookItem.authors} size={2} link={true}/>
                                {/* <Link to={authorPath} className={styles.link}><h2 className={styles.bookAuthor}>{authorDisplayString}</h2></Link> */}
                            </div>
                            <Clamp lines={4}
                                maxLines={100}
                                withTooltip={false}
                                withToggle={true}
                                showMoreElement={({ toggle }) => (
                                    <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                    </button>
                                )}
                                showLessElement={({ toggle }) => (
                                    <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                    </button>
                                )}>
                                <div className={styles.bookDescription}>{formattedFull} </div>
                            </Clamp>
                        </div>
                    </div>
                    <EditionBox bookItem={bookItem} />
                </div>
                {bookItem.userbookID ?
                    <div>
                        <div className={styles.rowTwo}>
                            <FieldsBox bookItem={bookItem} />
                            <ReviewBox bookItem={bookItem} />
                        </div>

                        <div className={styles.rowThree}>
                            <Recommendations bookItem={bookItem} />
                        </div>

                        <div className={styles.rowFour}>
                            <Journal bookItem={bookItem} />
                        </div>
                    </div>
                    : 
                    null
                }
            </div>
        );
    }

}