import React, { useState, useEffect } from "react";

import styles from "./Readings.module.css";

import { Reading } from "../Reading/Reading";
import { useBook, useBookUpdate } from "../../contexts/BookContext";
import { deleteReading, patchReading } from "../../api/Api";
import { NewReading } from "../Reading/NewReading";

export const Readings = () => {
        
    // ----------------------------------------------------- STATES AND HOOKS
    let token = localStorage.getItem("token"); 
    const bookItem = useBook();
    const bookUpdate = useBookUpdate();

    const [areReadings, setAreReadings] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    var changedReadings = [];
    // const [isAdding, setIsAdding] = useState(false);

    const [toBeDeleted, setToBeDeleted] = useState(null);

    useEffect(() => {
        // sets is editing to false if page is changed

        if (bookItem && bookItem.readings && bookItem.readings.length > 0) {
            // book has readings (has been read OR is being currently read)
            setAreReadings(true);

        } else {
            // are no readings AND not being currently read
            setAreReadings(false)
        }
    }, [bookItem])

    useEffect(() => {
        // removes reading from database
        if (toBeDeleted !== null) {
            removeReading();
        }
    }, [toBeDeleted])


    // ----------------------------------------------------- PRE-RENDER


    function handleEditClick() {
        // toggles editing state
        setIsEditing(!isEditing);
    }

    // function handleAddClick() {
    //     // toggles adds new reading input
    //     setIsAdding(!isAdding);
    // }

    async function handleSubmit() {
        // handles click of save button
        if (changedReadings.length > 0) {
            // only runs if there are changes to be saved
            let promises = []

            // creates promise for each change
            for (let i = 0; i < changedReadings.length; i++) {
                promises.push(
                    sendEditedReading(changedReadings[i])
                );
            }

            const [book] = await Promise.all(promises);

            // resets state when all completed
            bookUpdate(book);
            setIsEditing(false);
            changedReadings = [];
        }

    }

    async function sendEditedReading(readingInfo) {
        // sends changes to back
        return await patchReading(readingInfo.readingID, readingInfo, token)
            .then((editedBook) => {
                return editedBook
            })
            .catch((error) => {
                console.log("failed to patch reading")
                console.log(error)
            })
    }

    async function removeReading(){
        // deletes a tag from database
        await deleteReading(toBeDeleted, token)
            .then((editedBook) => {
                bookUpdate(editedBook);
                setToBeDeleted(null);
            })
            .catch((error) => {
                console.log("failed to remove reading")
                console.log(error)
            })
    }

    // ----------------------------------------------------- RENDER
    if ( areReadings ) {
        return (
            <section>
                <div className={styles.titleAndButton}>
                    <h3 className={styles.infoTitle}>Readings</h3>
                    <button
                        className={styles.editButton}
                        onClick={handleEditClick}>
                        <img src="/assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" />
                    </button>
                </div>
                <div className={styles.readings}>
                    {bookItem.readings.map((read) =>
                        <Reading readingItem={read} key={read.id} isEditing={isEditing} editedReadings = {changedReadings} setToBeDeleted={setToBeDeleted}/>
                    )}
                    {/* {isAdding ?
                        <NewReading setIsAdding={setIsAdding}/>
                        :
                        null
                    } */}
                    {isEditing ?
                        <div>
                            <h5 className={styles.message}>Readings with associated entries cannot be deleted</h5>
                            <div className={styles.buttons}>

                                {/* <button className={styles.addReadingButton} onClick={handleAddClick}>Add</button> */}
                                <button className={styles.saveButton} onClick={handleSubmit}>Save</button>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </section>
    );
    } else {
        return null;
    }

}