import React, { useEffect, useState } from "react";
import styles from "./Cover.module.css";
import { Link } from 'react-router-dom';

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getBookCover } from "../../api/Api";

export const Cover = (props) => {

    // props:
    // 
    // bookItem - object containing book infromation
    // loc - location of cover on page, for when a book appears more than once
    // size - S, M, L, or XL

    // ----------------------------------------------------- STATE AND HOOKS

    useEffect(() => {
        if (props.bookItem && props.bookItem.cover === "aws") { 
            // gets from aws if necessary
            getCover() 
        } else {
            document.getElementById(`cover${props.bookItem.bookID}${props.loc}`).src = props.bookItem.cover
        }
    },[props.bookItem])

    const bookPath = "/book/" + props.bookItem.bookID;
    let token = localStorage.getItem("token");

    // ----------------------------------------------------- PRE-RENDER

    async function getCover() {
        await getBookCover(props.bookItem.isbn, token)
            .then((response) => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            // Create an object URL for the response
            .then((response) => response.blob())
            .then((blob) => URL.createObjectURL(blob))
            // Update image
            .then((url) => {
                if (document.getElementById(`cover${props.bookItem.bookID}${props.loc}`)) { document.getElementById(`cover${props.bookItem.bookID}${props.loc}`).src = url }
            })
            .catch((err) => console.error(err));

    }
    // ----------------------------------------------------- RENDER


    switch (props.size) {
        case "S":
            return (
                <section>
                    <Link to={bookPath} className={styles.link}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.bookItem.bookID}${props.loc}`} className={styles.bookCoverS} alt="Cover of book" />
                    </Link>
                </section>
            );

        case "M":
            return (
                <section>
                    <Link to={bookPath} className={styles.link}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.bookItem.bookID}${props.loc}`} className={styles.bookCoverM} alt="Cover of book" />
                    </Link>
                </section>
            );

        case "L":
            return (
                <section>
                    <Link to={bookPath} className={styles.link}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.bookItem.bookID}${props.loc}`} className={styles.bookCoverL} alt="Cover of book" />
                    </Link>
                </section>
            );

        case "XL":
            return (
                <section>
                    <Link to={bookPath} className={styles.link}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.bookItem.bookID}${props.loc}`} className={styles.bookCoverXL} alt="Cover of book, XL" />
                    </Link>
                </section>
            );
    }
}