import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import styles from "./CompactBook.module.css";

import Markdown from 'react-markdown';
import Clamp from 'react-multiline-clamp';

import { Tag } from "../../Tag/Tag";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";


export const CompactBook = (props) => {
    
    // props: 
    //
    // bookItem: book object

    // ----------------------------------------------------- STATE AND HOOK

    const [formattedFull, setformattedFull] = useState("");

    const seriesPath = "/series/" + props.bookItem.series?.id;
    const bookPath = "/book/" + props.bookItem.bookID;

    useEffect(() => {
        if (props.bookItem && props.bookItem.review) {
            setformattedFull(formatReview(props.bookItem.review));
        }
    }, [props.bookItem.review])

    // ----------------------------------------------------- PRE-RENDER
    var tags = [];
    
    if (props.bookItem && props.bookItem.tags) { //checks that bookItem has propegated before accessing
        if (props.bookItem.tags.length > 4) { //checks number of tags
            for (let i = 0; i < 4; i++) { //only renders first 4
                tags.push( <div key={props.bookItem.tags[i].id}><Tag tagItem={props.bookItem.tags[i]} /></div>)
            }
        } else { //renders all when less than 4 total
            tags = props.bookItem.tags.map((tag) =>
                <div key={tag.id} ><Tag tagItem={tag}/></div>
            )
        }
    }

    function formatReview(review) {
        //adds white space to descritpion
        var formattedReview = [];
        let index = 0;

        let reviewPeices = review.split('\n'); //splits review string by newlines
        for (const element of reviewPeices) {
            index++;
            if (!element) { //if empty it means there was a blank line
                formattedReview.push(<div className={styles.spacer} key={index}></div>)
            } else {
                formattedReview.push(<Markdown key={index}>{element}</Markdown>) //adds string segment to array
            }
        }
        return (formattedReview);
    }


    // ----------------------------------------------------- RENDER coverIsLoaded ? styles.bookCover : 
    return (
        <div className={styles.compactBook} >
            <Cover bookItem = {props.bookItem} size = "M" loc="compactBook"/>
            <div>
                <div className={styles.infoAndTags}>
                    <div className={styles.titleAndSeriesAndAuthor}>
                        <Link to={bookPath} className={styles.link}>
                            <div className={styles.bookTitle}>
                                {props.bookItem.title} 
                                {props.bookItem.favorited ? <img className = {styles.starIconFilled} alt="Favorite star" /> : null}
                            </div>
                            </Link>
                        {props.bookItem.series != null ?
                            <div>
                                <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                            </div> :
                            null
                        }
                        <Author authorsList={props.bookItem.authors} size={1} link={true}/>
                    </div>
                    <div className={styles.tags}>{tags}</div>
                </div>
                <div>
                    <Clamp lines={1} 
                            withToggle={true}
                            maxLines={100}
                            withTooltip={false}
                            showMoreElement = { ({toggle}) => (
                                <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                </button>
                            )}
                            showLessElement = { ({toggle}) => (
                                <button className={styles.readMoreOrLessButton}
                                        onClick={toggle} >
                                        <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                </button>
                            )}>
                        <div className={styles.bookReview}>{formattedFull} </div>
                    </Clamp>

                </div>
            </div>
        </div>
    );
}