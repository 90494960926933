import React from "react";
import styles from "./StatusDropdownItem.module.css";

export const StatusDropdownItem = (props) => {

    //props:
    //
    //displayValue - string, human friendly display
    //onClick - function, switches selected value

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    let isDelete = (props.displayValue === "Delete From Library");

    // ----------------------------------------------------- RENDER
 
    return (
        <div className={isDelete ? styles.deleteDropdownItem : styles.dropdownItem} onClick={props.onClick}>
            {props.displayValue}
        </div>
    );

}