import React from "react";
import styles from "./Loader.module.css";

export const Loader = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <img src="/assets/images/loading.gif" alt="gif of a book flipping pages" />
        </section>
    )
}