import React from "react";
import styles from "./StatusDropdownButton.module.css";

export const StatusDropdownButton = (props) => {

    //props:
    //
    //isOpen - bool, if the drop down is open or not
    //toggle - function to switch isOpen
    //buttonText - value to display in box of dropdown

    return (
        <button className={props.open ? styles.dropdownButtonOpen : styles.dropdownButtonClosed } 
            onClick={props.toggle}>
            {props.buttonText}
            {props.open ?
                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="open menu icon" />
                :
                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="closed menu icon" />
            }

        </button>
    );
}