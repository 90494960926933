import React, { useState, useContext, createContext } from "react";

export const SearchContext = createContext(null);
export const SearchUpdateContext = createContext(null);
export const SearchStringContext = createContext(null);

export function useSearch() {
    //hook to use search context
    const search = useContext(SearchContext);
    if (search === undefined) {
        console.log("There has been an error, check SearchContext.js");
    }
    return search;
}

export function useSearchUpdate() {
    //hook to update search context
    const searchUpdate = useContext(SearchUpdateContext);
    if( searchUpdate === undefined) {
        console.log("There has been an error, check SearchContext.js");
    }
    return searchUpdate;
}

export function useSearchString() {
    //hook to update search context
    const searchString = useContext(SearchStringContext);
    if ( searchString === undefined) {
        console.log("There has been an error, check SearchContext.js");
    }
    return searchString;
}


export function SearchContextProvider( { children }) {

    const [searchType, setSearchType] = useState("Title");
    const [activeIndex, setActiveIndex] = useState(null);
    const [searchString, setSearchString] = useState("");

    const setType = (type) => {
        setSearchType(type);
    }

    const setIndex = (i) => {
        setActiveIndex(i);
    }

    const setString = (string) => {
        setSearchString(string);
    }

    return(
        <SearchContext.Provider value={[searchType, activeIndex]}>
            <SearchUpdateContext.Provider value={[setType,  setIndex]}>
                <SearchStringContext.Provider value={[searchString, setString]}>
                {children}
                </SearchStringContext.Provider>
            </SearchUpdateContext.Provider>
        </SearchContext.Provider>
    )
}