import React, { useEffect } from 'react';
import styles from "./pages.module.css";

import { useBookUpdate } from '../contexts/BookContext';

import { HomeBanner } from '../comonents/Banners/HomeBanner';
import { FavoriteBooks } from '../comonents/FavoriteBooks/FavoriteBooks';
import { Footer } from '../comonents/Footer/Footer';


export default function Home() {

    // ----------------------------------------------------- STATE AND HOOKS

    const bookUpdater = useBookUpdate();

    useEffect(() => {
        //resets book context to avoid flash of last loaded book
        bookUpdater({})
    }, [])

    // ----------------------------------------------------- RENDER
    return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <HomeBanner />
                    <FavoriteBooks />
                </div>
                <Footer />
            </div>
    );
}