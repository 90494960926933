import React from "react";
import styles from "./TagHeader.module.css";

export const TagHeader = (props) => {
    // props: 
    // 
    // headerValue - string to be displayed in header

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <h3 className={styles.headerValue}>{props.headerValue}</h3>
        </section>
    )
}