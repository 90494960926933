import React, { useEffect, useState } from "react";
import styles from "./NewEntry.module.css";

import Textarea from 'rc-textarea';


export const NewEntry = (props) => {

    // props:
    //
    // bookItem - object containing all book information
    // setNewEntry - state setter to invoke post entry
    // oldEntry - optional, if editing an old entry instead of starting from sctatch
    // setIsAdding - state setter for cancel adding

    // ----------------------------------------------------- STATE AND HOOK

    const [pageTracker, setPageTracker] = useState(); //update current page
    const [titleTracker, setTitleTracker] = useState("");
    const [bodyTracker, setBodyTracker] = useState("");

    useEffect( () => { // sets page tracker to 0 if progress is null
        if (props.bookItem && props.bookItem.progress) {
            setPageTracker(props.bookItem.progress)
        } else { 
            setPageTracker(0)
        }
    },[props.bookItem])

    // ----------------------------------------------------- PRE-RENDER

    const changePage = (event) => {
        // catches changes to page number
        setPageTracker(event.target.value);
    }

    const changeTitle = (event) => {
        // catches changes to title input
        setTitleTracker(event.target.value)
    }

    const changeBody = (event) => {
        // catches changes to body input
        setBodyTracker(event.target.value);
    }

    const submitEntry = () => {
        // sets new entry to state var of journal
        let entryPostModel = {
            title: titleTracker,
            body : bodyTracker,
            page : pageTracker,
        }
        props.setNewEntry(entryPostModel);
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <input className={styles.input} key="title input"
                    type="text"
                    placeholder="Title of new journal entry" 
                    value={titleTracker}
                    onChange={changeTitle}/>
            <input className={styles.input} key="page input"
                    type="number"
                    value={pageTracker}
                    onChange={changePage}
                    max={props.bookItem.numPages}/>
            <Textarea className={styles.inputBody} key="body input"
                    autoSize={ {minRows: 10} }
                    type="text"
                    placeholder="Body of new journal entry" 
                    value={bodyTracker}
                    onChange={changeBody}/>
            <div className={styles.saveAndCancel}>
                <button type="submit" className={styles.cancelButton} onClick={ () => props.setIsAdding(false) }>Cancel</button>
                <button type="submit" className={styles.submitButton} onClick={ () => submitEntry() }>Submit</button>
            </div>
        </section>
    )
}