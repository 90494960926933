import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./TagBook.module.css";
import { Tag } from "../../Tag/Tag";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const TagBook = (props) => {

    // props:
    //
    // bookItem - book object
    
    // ----------------------------------------------------- STATE AND HOOK

    const [isOpen, setIsOpen] = useState(false); //state of truncated review text block, open/close
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    const bookPath = "/book/" + props.bookItem.bookID;
    const seriesPath = "/series/" + props.bookItem.series?.id;

    useEffect(()=> {
        if (props.bookItem.tags.length > 2) {setShowReadMoreButton(true)}
    },[props.bookItem.tags])

    // ----------------------------------------------------- PRE-RENDER
    let tags = [] 

    if (props.bookItem && props.bookItem.tags) {
        for (let i = 0; i < props.bookItem.tags.length; i++) {
            tags.push(<Tag tagItem={props.bookItem.tags[i]} key={i}/>)
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}> 
                <Cover bookItem = {props.bookItem} size = {window.screen.width > 500 ? "L" : "M"} loc="tagBook"/>
                <div className={styles.bookInfo}>   
                    <Link to={bookPath} className={styles.link}><h2 className={styles.bookTitle}>{props.bookItem.title}</h2></Link>
                    {props.bookItem.series != null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                    </div> :
                    null}
                    <Author authorsList={props.bookItem.authors} size={1} link={true}/>
                    <div className={styles.tags}>
                    <div className= {isOpen ? styles.tagsInBook : styles.tagsInBookClosed}>{tags}</div>
                    {showReadMoreButton ? (
                        <button className={styles.readMoreOrLessButton}
                            onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? 
                                <img className={styles.carrot} src= "/assets/images/upCarrot.svg" alt="read less"/> 
                                : 
                                <img className={styles.carrot} src= "/assets/images/downCarrot.svg" alt="read more"/>
                            }
                        </button>
                    ): null}
                    </div>
                </div>
            </div>
            
        </section>
    );
}