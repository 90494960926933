import React, { useEffect, useState } from 'react';
import styles from "./CurrentlyReading.module.css";
import { getBookPage, getBooks, getBooksAllAccess } from '../../api/Api';

import { CurrentlyReadingBook } from "../BookTypes/CurrentlyReadingBook/CurrentlyReadingBook";

export const CurrentlyReading = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [books, setBooks] = useState([]);
    const [bookObjects, setBookObjects] = useState()

    let token = localStorage.getItem("token");

    useEffect(() => { //runs on first render
        fetchBooks();
    }, []);

    // ----------------------------------------------------- LOGIC

    function reloadBooks() {
        fetchBooks();
    }

    function makeBookItems(foundBooks) {
        //makes book render objects from fetched books
        if (foundBooks.length > 0)  {
            const listOfCurrentlyReading = foundBooks.map((book) =>
                <CurrentlyReadingBook bookItem={book} reload={reloadBooks} key={book.isbn} />
            );
            setBookObjects(listOfCurrentlyReading);

        } else {
            setBookObjects (
                <div className={styles.emptyShelf}>
                    <h1>This shelf is currently empty</h1>
                </div>
            );
        }
    }

    async function fetchBooks() {
        // gets currently reading books
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access 
            await getBooksAllAccess("currentlyReading", token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([])
                    console.log(error)
                });
        } else {
            // limited access
            await getBooks("currentlyReading", token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([])
                    console.log(error)
                });
        }
    }

    // ----------------------------------------------------- RENDER



    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Reading</h1>
            <div className={styles.content}>
                <ul>{bookObjects}</ul>
            </div>
        </section>

    );

}