import React from "react";
import styles from "./SmallDropdownItem.module.css";

export const SmallDropdownItem = (props) => {

    //props:
    //
    //displayValue - string, human friendly display
    //onClick - function, switches selected value

    return (
        <div className={styles.dropdownItem} onClick={props.onClick}>
            {props.displayValue}
        </div>
    );

}