import React from "react";
import styles from "./pages.module.css";

import { TagBanner } from "../comonents/Banners/TagBanner";
import { Footer } from "../comonents/Footer/Footer";

export default function Tag () {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <TagBanner />
            </div>
            <Footer />
        </div>
    );
}