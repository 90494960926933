import React from "react";
import styles from "./pages.module.css";

import { NavigationBar } from "../comonents/NavBar/NavigationBar";
import { AddBookForm } from "../comonents/AddBookForm/AddBookForm";
import { useLocation } from "react-router-dom";

import { useSearchString } from "../contexts/SearchContext";
import { Footer } from "../comonents/Footer/Footer";

export const AddBook = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [searchString, setSearchString ] = useSearchString();

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <AddBookForm />
            </div>
            <Footer />
        </section>
    );
}