import React from "react";
import { Link } from "react-router-dom";

import styles from "./WatchBook.module.css";
import { Cover } from "../../Cover/Cover";


export const WatchBook = (props) => {

    // props:
    //
    // bookItem - all book data

    // ----------------------------------------------------- PRE-RENDER
    let bookPath = "/book/" + props.bookItem.bookID;
    
    let dateString = props.bookItem.pub + "T00:00"
    let temp = new Date(dateString)
    let pubString = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: window.screen.width > 500 ? "long" : "short", year: 'numeric' });

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <Cover bookItem={props.bookItem} size = {window.screen.width > 500 ? "L" : "M"} loc="watchBook"/>
            <Link to={bookPath} className={styles.link}>
                <div className={styles.dateAndOutNowMessage}>
                    {new Date(dateString) <= new Date ? <h6 className={styles.outNow}>OUT NOW: </h6> : null}
                    <h6 className={styles.pubDate}>{pubString}</h6>
                </div>
            </Link>
        </section>
    );
}