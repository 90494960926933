import React, { useEffect, useRef, useState } from "react";

import Markdown from 'react-markdown'
import Textarea from 'rc-textarea';

import styles from "./ReviewBox.module.css";

import { patchBook } from '../../api/Api';
import { useBook, useBookUpdate } from "../../contexts/BookContext";
import { MarkdownInfo } from "../MarkdownInfo/MarkdownInfo";


export const ReviewBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const bookItem = useBook();
    const setBook = useBookUpdate();

    let token = localStorage.getItem("token");

    const infoRef = useRef();
    const contentRef = useRef();

    const [isEditingReview, setIsEditingReview] = useState(false); //is edit box open?
    const [isInfoOpen, setIsInfoOpen] = useState(false); // is markdown info open?


    const [editedReview, setEditedReview] = useState(bookItem.review);
    const [formatted, setFormatted] = useState();

    useEffect(() => { // sets initial state once props load 
        setEditedReview(bookItem.review);
        if (bookItem.review) { 
            setFormatted(formatReview(bookItem.review))
        } else {
            // clears review if seach to book not in library
            setFormatted(null)  
        }
    }, [bookItem.review])

    // ----------------------------------------------------- LOGIC

    const updateReviewEditing = () => { //opens or closes textarea
        setIsEditingReview(!isEditingReview);
    }

    const catchChanges = (event) => { //catches the changes in textarea
        setEditedReview(event.target.value);
    }

    const reviewUpdateObj = { //object to be sent to db
        review: editedReview
    }

    const handleInfoClick = () => {
        // toggles isInfoOpen
        setIsInfoOpen(!isInfoOpen)
    }

    async function sendChanges() { //sends changed review to database
        await patchBook(bookItem.userbookID, reviewUpdateObj, token)
            .then((book) => {
                setBook(book);
                setIsEditingReview(false)
            })
            .catch((error) => {
                console.log("failed to patch review");
                console.log(error)
            })
        setFormatted(formatReview(editedReview)); //formats review after sent
    }

    //adding white space to the review
    function formatReview(review) {
        let formattedReview = []
        let reviewPeices = review.split('\n'); //splits review string by newlines
        for (let i = 0; i < reviewPeices.length; i++) {
            if (!reviewPeices[i]) { //if empty it means there was a blank line
                formattedReview.push(<div key={i} className={styles.spacer}></div>)
            } else {
                formattedReview.push(<Markdown key={i}>{reviewPeices[i]}</Markdown>) //adds string segment to array
            }
        }
        return(formattedReview);
    }


    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.reviewContainer}>
            <div className={styles.titleAndButtons}>
                <h3 className={styles.infoTitle}>Review</h3>
                <div className={styles.buttons}>
                    <button
                        className={styles.editButton}
                        onClick = {handleInfoClick}>
                        <img src="/assets/images/info.svg"
                            id="InfoIcon"
                            className={styles.editButtonImage}
                            alt="Info Icon" />
                    </button>
                    <button
                        className={styles.editButton}
                        onClick={updateReviewEditing}>
                        <img src="/assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" />
                    </button>
                </div>
            </div>
            {isInfoOpen ? 
                <div className={styles.markdownInfo} > <MarkdownInfo toggle = {setIsInfoOpen} isOpen={isInfoOpen}/> </div>
                :
                null
            }
            {isEditingReview ? (
                <div className={styles.editingReviewArea}>
                    <Textarea name="Review"
                        autoSize={ {minRows: 10} }
                        className={styles.reviewTextBox}
                        defaultValue={editedReview}
                        autoFocus={true}
                        placeholder="Review"
                        onChange={catchChanges}
                    />
                    <button className={styles.submitButton} onClick={sendChanges}>Submit</button>
                </div>
            ) : (
                //<div className={styles.review}>{formatted}</div>
                <div className={styles.review}>{formatted}</div>
            )}
        </div>
    )
}