import React, { useEffect, useRef, useState } from "react";
import styles from "./ProfileDropdown.module.css";

import { ProfileDropdownButton } from "./ProfileDropdownButton";
import { ProfileDropdownContent } from "./ProfileDropdownContent";

export const ProfileDropdown = () => {
    
    // ----------------------------------------------------- STATE AND HOOKS
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef();
    const contentRef = useRef();

    useEffect(() => { //closes dropdown when somewhere else is clicked
        const handler = (event) => {
            //closes if somewhere else is clicked
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
            //closes if content is clicked
            if (contentRef.current && contentRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("click", handler);

        return () => { //cleans up listener
            document.removeEventListener("click", handler)
        }

    }, [dropdownRef, contentRef]);

    // ----------------------------------------------------- PRE-RENDER

    function handleClick() {
        setIsOpen(!isOpen);
    }

    // ----------------------------------------------------- RENDER


    return (
        <section className={styles.container} ref={dropdownRef}>
            <ProfileDropdownButton isOpen ={isOpen} toggle={handleClick}/>
            <div ref={contentRef}>
                <ProfileDropdownContent isOpen={isOpen}/>
            </div>

        </section>
    )
}