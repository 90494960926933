import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./TagBanner.module.css";

import { getTags, deleteTag } from "../../api/Api";

import { Tag } from "../Tag/Tag";
import { TagBook } from "../BookTypes/TagBook/TagBook";
import { useBookUpdate } from "../../contexts/BookContext";
import { TagHeader } from "../Tag/TagHeader";
import { Loader } from "../Loader/Loader";

export const TagBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [allTags, setAllTags] = useState([]); // all tags in db
    const [allTagObjects, setAllTagObjects] = useState([]); // all the Tag objects for all tags in db
    const [allTagBookObjects, setAllTagBookObjects] = useState([]) // all the TagBook objects for selected tag

    const [selectedTag, setSelectedTag] = useState({});

    let token = localStorage.getItem("token");
    const location = useLocation(); // gets path from url

    const bookUpdater = useBookUpdate();

    // gets tag ID from url or sets to null
    let selectedTagID = null
    if (location.pathname === "/tags/") {
        selectedTagID = null;
    } else {
        selectedTagID = location.pathname.split("/")[2]; // gets tag id from url
    }

    let newPath = "/tags/"

    useEffect(() => {
        // gets all tags on first render
        setAllTagObjects(<div className={styles.loader}><Loader /></div>)
        fetchTags();
        bookUpdater({}); // resets book context to avoid flash of previous book
    }, []);

    useEffect(() => {
        // runs when all tags come back from db or location changes

        allTags.forEach((tag) => { // gets the tag object for the currently selected tag
            if (tag.id == selectedTagID) {
                setSelectedTag(tag);
                getTagBookObjects(tag.books);
                getTagObjects(allTags);
            }
        });
        
    }, [allTags, location, selectedTag]);


    // ----------------------------------------------------- PRE-RENDER

    async function fetchTags() {
        // gets all tags
        await getTags(token)
            .then((tags) => {
                //allTags = tags;
                setAllTags(tags);
                getTagObjects(tags);
            })
            .catch((error) => {
                console.log("failed to get tags")
                console.log(error)
            })
        if (allTags.length > 0) { newPath = newPath + allTags[0].id }
    }

    async function deleteTagFromDB() {
        await deleteTag(selectedTagID,token);
    }

    function getTagObjects(tags) {
        // makes tag objects

        if (tags.length > 0) {
            let tempTagsList = [];
            let tempLetterList = [];
            let lastHeader = null;

            for (let i = 0; i < tags.length; i++) {
                // adds header for each letter seen to array
                if (!(/[a-zA-Z]/).test(tags[i].tagValue.slice(0,1))) {
                    // group tags that do not start with a letter 
                    if (lastHeader !== "num") {
                        // adds tag header to letter group
                        tempLetterList.push( <div key={"num"}>
                                                <TagHeader headerValue={"123!?"}/>
                                            </div> );
                        // updates last seen header
                        lastHeader = "num";
                    }
                }
                else if (tags[i].tagValue.slice(0,1) != lastHeader) {
                    // groups tag by first letter
                    if (lastHeader != null) {
                        // pushes letter group to larger array if it is not the first time through the loop
                        tempTagsList.push( <div className={styles.letterGroup} key={"letterGroup:"+lastHeader}>
                                                {tempLetterList}
                                            </div> );
                        tempLetterList = [];
                    }
                    
                    // adds tag header to letter group
                    tempLetterList.push( <div key={tags[i].tagValue.slice(0,1)}>
                                            <TagHeader headerValue={tags[i].tagValue.slice(0,1).toUpperCase()}/>
                                        </div> );
                    // updates last seen header
                    lastHeader = tags[i].tagValue.slice(0,1);
                }
                // adds tag to letter group
                tempLetterList.push( <div className={styles.tag} key={tags[i].id}>
                                        <Tag tagItem={tags[i]} selected={selectedTagID} nextTag={tags[0].id} />
                                    </div> );

            }
            
            // adds all groups to main array
            setAllTagObjects([tempTagsList, tempLetterList]);
        } else {
            // empty message for no tags
            setAllTagObjects( <div className={styles.emptyShelf}>
                                <h1>This shelf is currently empty </h1>
                            </div> )
        }
    }

    function getTagBookObjects(selectedTagBooks) {
        // creates all TagBooks for currently selected tag
        let tempTagBookList = [];
        if (selectedTagBooks.length > 0) {
            // sorts books by author
            let sortedBooks = selectedTagBooks.sort(function (a, b) {
                if (a.authors[0].last > b.authors[0].last) {
                    return 1;
                }
                if (a.authors[0].last < b.authors[0].last) {
                    return -1;
                }
                return 0;
            });
            // makes objects
            tempTagBookList = sortedBooks.map((book) =>
                <TagBook bookItem={book} key={book.isbn} />
            );
            // saves objects
            setAllTagBookObjects(tempTagBookList)
        } else {
            // no books with tag, empty
            setAllTagBookObjects (
                <div className={styles.emptyMessageContainer}>
                    <h4 className={styles.emptyMessage}> There are no books with this tag</h4>
                    <Link to={newPath} ><button className={styles.removeTagButton} onClick={handleDeleteTag}>Delete Tag</button></Link>
                </div>
            )
        }
    }

    function handleDeleteTag() {
        // removes from cached tags
        deleteTagFromDB() // makes call to backend
            .then(() => {
                fetchTags();
            });
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Tags</h1>
            <div className={styles.content}>
                <div className={styles.tagGridContainer}>
                    {allTagObjects}
                </div>
            </div>
            {selectedTagID != null ?
                <div className={styles.selectedBooksContainer}>
                    <h1 className={styles.sectionTitle}>Books taged: {selectedTag.tagValue}</h1>
                    <div className={styles.content}>
                        <div className={styles.bookGridContainer}>
                            {allTagBookObjects}
                        </div>
                    </div>
                </div> : null
            }
        </section>
    );
}