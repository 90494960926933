import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./pages.module.css";

import { getAuthor, getAuthorBooks } from "../api/Api"
import { useBookUpdate } from "../contexts/BookContext";

import { AuthorBanner } from "../comonents/Banners/AuthorBanner";
import { Footer } from "../comonents/Footer/Footer";

export default function Author() {

    // ----------------------------------------------------- STATE AND HOOKS

    const authorID = useParams().id;
    const [author, setAuthor] = useState({});

    let token = localStorage.getItem("token");

    const bookUpdater = useBookUpdate();

    useEffect(() => {
        // gets author on first render
        fetchAuthor();
        bookUpdater({}); //resets book to avoid flash
    }, [authorID]);


    // ----------------------------------------------------- PRE-RENDER

    async function fetchAuthor(){
        // fetches author from back
        await getAuthor(authorID)
            .then((foundAuthor) => {
                setAuthor(foundAuthor);
            })
            .catch((error) => {
                console.log("failed to get author");
                console.log(error);
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <AuthorBanner authorItem = {author}/>
            </div>
                
            <Footer />
        </div>
    );
}