import React from "react";

import styles from "./SearchTag.module.css";
import { Link } from "react-router-dom";

export const SearchTag = (props) => {
    //props:
    //
    //tagItem - object containing tag info
    //active - bool, indicates if author is selected or not

    // ----------------------------------------------------- PRE-RENDER 

    let tagPath = "/tags/" + props.tagItem.id;

    // ----------------------------------------------------- RENDER 

    return (
        <Link to={tagPath} className={styles.link}>
            <section className={props.active ? styles.containerActive : styles.container}>
                <div className={styles.content}>
                    <h2 className={styles.tagValue}>{props.tagItem.tagValue}</h2>
                </div>
            </section>
        </Link>
    )
}