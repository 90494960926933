import React, { useEffect, useRef, useState } from "react";

import { useSearch, useSearchUpdate } from "../../contexts/SearchContext";

import styles from "./Search.module.css";

import { SearchBar } from "./SearchBar";
import { SearchResults } from "./SearchResults";
import { SmallDropdown } from "../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../SmallDropdown/SmallDropdownItem";
import { useNavigate } from "react-router-dom";


export const Search = () => {

    // ----------------------------------------------------- STATES AND HOOKS

    const [searchType, activeIndex] = useSearch();
    const [setSearchType, setActiveIndex] = useSearchUpdate();

    const [searchResults, setSearchResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    const resultsRef = useRef();

    useEffect( () => { //closes search when elsewhere is clicked
        const handler = (event) => { 
            //listening for click elsewhere
            if(resultsRef.current && !resultsRef.current.contains(event.target)) {
                setIsOpen(false)
            }
            //closes if item clicked
            if(resultsRef.current && resultsRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener("click", handler);

        return () => { //cleans up listener
            document.removeEventListener("click", handler)
        }
    }, [resultsRef]);


    useEffect(() => { //resets key through index to null when results are closed
        setActiveIndex(null);
    }, [isOpen])


    // ----------------------------------------------------- PRE-RENDER


    const options = [
        <SmallDropdownItem key="Author" displayValue="Author" onClick={ () => setSearchType("Author")}/>,
        <SmallDropdownItem key="ISBN" displayValue="Isbn" onClick={ () => setSearchType("Isbn")}/>,
        <SmallDropdownItem key="Tag" displayValue="Tag" onClick={ () => setSearchType("Tag")}/>,
        <SmallDropdownItem key="Title" displayValue="Title" onClick={ () => setSearchType("Title")}/>,
        <SmallDropdownItem key="Series" displayValue="Series" onClick={ () => setSearchType("Series")}/>
    ]


    function handleKeyDownOnDropDown(event) {
       //selects search type with key presses
        if (event.keyCode === 84) {
            setSearchType("Title")
        } else if (event.keyCode === 65) {
            setSearchType("Author")
        } else if (event.keyCode === 71) {
            setSearchType("Tag")
        } else if (event.keyCode === 83) {
            setSearchType("Series")
        } else if (event.keyCode === 73) {
            setSearchType("Isbn")
        }
    }

    function handleKeyDownSearch(event) {
        //selects result with key presses

        if (searchResults.length != 0) {
            if (event.keyCode === 40) { //down arrow
                if (activeIndex == searchResults.length) { //wraps around bottom
                    setActiveIndex(1)

                } else {
                    setActiveIndex(activeIndex + 1)
                }

            } else if (event.keyCode == 38) {  //up arrow
                if (activeIndex == 1) { //wraps around top
                    setActiveIndex(5);

                } else {
                    setActiveIndex(activeIndex-1);
                }

            } else if (activeIndex != null && event.keyCode == 13) { //enter, changes page
                if (searchType == "Title" || searchType == "Isbn") {
                    setIsOpen(false);
                    navigate(`/book/` + searchResults[activeIndex-1].bookID);

                } else if (searchType == "Author") {
                    setIsOpen(false);
                    navigate(`/author/` + searchResults[activeIndex-1].id);

                } else if (searchType === "Tag") {
                    setIsOpen(false);
                    navigate(`/tags/` + searchResults[activeIndex-1].id);

                } else { //series
                    setIsOpen(false);
                    navigate(`/series/` + searchResults[activeIndex-1].id);
                }
                
            }
        } else if (searchResults.length === 0 && event.keyCode === 13) {
            // opens add if no results found on enter press
            setIsOpen(false);
            navigate(`/addbook`);
        }
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.bigContainer}>
            <div className={styles.container}>
                <div className={styles.barAndDropdown}>
                    <div className={styles.barContainer} onKeyDown={handleKeyDownSearch}>
                        <SearchBar setSearchResults={setSearchResults} setIsOpen={setIsOpen} />
                    </div>
                    <div className={styles.dropdown} onKeyDown={handleKeyDownOnDropDown}>
                        <SmallDropdown buttonText={searchType} content={options} fontSize={window.screen.width < 500 ? 16 : 24}/>
                    </div>
                </div>
                <div className={styles.results} ref={resultsRef}>
                    {isOpen ? <SearchResults results={searchResults} /> : null}
                </div>
            </div>
        </section>
    );
}