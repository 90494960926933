import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from "./LibraryList.module.css";

import { getBookPage, getBookPageAllAccess, getTotalItems, getTotalItemsAllAccess } from '../../api/Api';

import { Dropdown } from '../Dropdown/Dropdown';
import { CompactBook } from '../BookTypes/CompactBook/CompactBook';
import { DropdownItem } from '../Dropdown/DropdownItem';
import { PageSelector } from '../PageSelector/PageSelector';
import { useBookUpdate } from '../../contexts/BookContext';
import { Loader } from '../Loader/Loader';


export const LibraryList = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const bookUpdater = useBookUpdate();
    const [isLoaded, setIsLoaded] = useState(false);

    const [books, setBooks] = useState([]);
    const [listOfBooks, setListOfBooks] = useState([]);
    const [totalPages, setTotalPages] = useState();

    const [displayOptions, setDisplayOptions] = useState([]); // options to be shown in library selector dropdown

    const [searchParams, setSearchParams] = useSearchParams({ "page": "1", "library": "read" }); // saves page to url
    const page = searchParams.get("page"); // page value


    const [selectedLibrary, setSelectedLibrary] = useState(searchParams.get("library"));

    let pageSize = 10;
    let token = localStorage.getItem("token");

    useEffect(() => { 
        bookUpdater({}); // sets saved book to empty to avoid flash of last clicked book
    }, []);

    useEffect(() => { makeBookItems(); }, [books]); // gets book cards when books arrive from back
    useEffect(() => { 
        setListOfBooks(
            <div className={styles.loadingGif}><Loader /></div>
        );
        fetchBooks();

    }, [page]) // triggers rerender when page changes

    useEffect(() => {  // trigger rerender when selected library changes
        setDisplayOptions(allOptions.filter(isInList))
        
        setListOfBooks(
            <div className={styles.loadingGif}><Loader /></div>
        );

        fetchBooks()
        fetchPageTotal();


        
    }, [selectedLibrary]);

    // ----------------------------------------------------- PRE- RENDER

    const statusDict = {
        "library" : "Library",
        "read": "Read",
        "DNF": "DNF",
        "TBR": "TBR",
        "watch": "Watching"
    }

    const allOptions = [ //avaliable libraries
        <DropdownItem key="library" displayValue="Library" onClick={() => handleLibrayChange("library")}></DropdownItem>,
        <DropdownItem key="watch" displayValue="Watching" onClick={() => handleLibrayChange("watch")}></DropdownItem>,
        <DropdownItem key="TBR" displayValue="TBR" onClick={() => handleLibrayChange("TBR")}></DropdownItem>,
        <DropdownItem key="read" displayValue="Read" onClick={() => handleLibrayChange("read")}></DropdownItem>,
        <DropdownItem key="DNF" displayValue="DNF" onClick={() => handleLibrayChange("DNF")}></DropdownItem>,
    ];

    // ----------------------------------------------------- LOGIC

    async function fetchBooks() {
        // gets books from database

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBookPageAllAccess(page, pageSize, selectedLibrary, token)
                .then((books) => {
                    if (page === searchParams.get("page")) { // prevents a call from being slow and overwritting new page data
                        setBooks(books);
                        setIsLoaded(true);
                    }
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        } else {
            // limited access
            await getBookPage(page, pageSize, selectedLibrary, token)
                .then((books) => {
                    if (page === searchParams.get("page")) { // prevents a call from being slow and overwritting new page data
                        setBooks(books);
                        setIsLoaded(true);
                    }
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        }
    }

    async function fetchPageTotal() {
        // gets the total number of books for a given library from back and calculates total number of pages

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getTotalItemsAllAccess(selectedLibrary, token)
                .then((itemCount) => {
                    let totalPages = Math.ceil(itemCount / pageSize)
                    setTotalPages(totalPages)

                    // makePageSelector(totalPages);

                    if (page > totalPages) {
                        setSearchParams(prev => { // re-sets page back to one if new total pages is less than currently selected
                            prev.set("page", 1)
                            return prev
                        }, { replace: true });
                    }
                })
                .catch((error) => {
                    console.log("failed to get total number of pages");
                    console.log(error)
                })
        } else {
            await getTotalItems(selectedLibrary, token)
                .then((itemCount) => {
                    let totalPages = Math.ceil(itemCount / pageSize)
                    setTotalPages(totalPages)

                    // makePageSelector(totalPages);

                    if (page > totalPages) {
                        setSearchParams(prev => { // re-sets page back to one if new total pages is less than currently selected
                            prev.set("page", 1)
                            return prev
                        }, { replace: true });
                    }
                })
                .catch((error) => {
                    console.log("failed to get total number of pages");
                    console.log(error)
                })
        }

    }


    function isInList(i) {
        // checks if Dropdown item is currently selected and excludes it from options
        return !(i.key === selectedLibrary);
    }

    function makeBookItems() {
        // makes list of book items
        if (books.length > 0) {
            let tempList = books.map((book) =>
                <CompactBook bookItem={book} key={book.isbn} />);
            setListOfBooks(tempList);
        } else if (books.length == 0 && isLoaded) {
            setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }
    }

    function handleClick(newPage) {
        // handles click to new page buttons
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

    }

    function incPage() {
        // increases page by one as caused by next button on bottom of page
        let newPage = Number(page) + 1;
        if (newPage > totalPages) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0, 0); // scrolls window to top
    }

    function decPage() {
        // decreases page by one as caused by back button on bottom of page
        let newPage = Number(page) - 1;
        if (newPage < 1) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0,0)
    }

    function handleLibrayChange(newLibrary) {
        // changes library selection
        setSearchParams(prev => {
            prev.set("library", newLibrary)
            return prev
        }, { replace: true });

        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });
        setSelectedLibrary(newLibrary);
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.librarySelector}>
                    <Dropdown buttonText={statusDict[selectedLibrary]} content={displayOptions} fontSize={window.screen.width < 500 ? 32 : 48}/>
                </div>
                <div className={styles.pageSelector}>
                    {totalPages > 1 ? 
                        <PageSelector totalPages={totalPages} switchPage={handleClick} selectedPage={page} />
                        : 
                        null
                    }
                </div>
            </div>
            <div className={styles.content}>
                {listOfBooks}
            </div>
            {totalPages > 1 ?
                <div className={styles.buttonContainer}>
                    {Number(page) !== 1 ? 
                        <button className={styles.carrotBackButton} onClick={decPage} > <img className={styles.carrot} src="/assets/images/backIcon.svg"  /> </button>
                        :
                        <div className={styles.carrotNextButton} />
                    }
                    {Number(page) !== totalPages ?
                        <button className={styles.carrotNextButton} onClick={incPage} > <img className={styles.carrot}  src="/assets/images/nextIcon.svg" /> </button>
                        :
                        <div className={styles.carrotBackButton} />
                    }
                </div> 
                : 
                null
            }
        </div>
    );

}