import React, { useEffect, useState } from "react";
import styles from "./AddBookForm.module.css";
import Textarea from 'rc-textarea';

import { addAuthor, addBook, addSeries, getBookFromOpenLibrary, getBooksFromGoogle, getFromSearch, getWorkInfoFromOpenLibrary, postBookCover } from "../../api/Api";

import { useSearchString } from "../../contexts/SearchContext";

import { OpenLibraryBook } from "../BookTypes/OpenLibrary/OpenLibraryBook";
import { GoogleBook } from "../BookTypes/Google/GoogleBook";

import { SmallDropdown } from "../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../SmallDropdown/SmallDropdownItem";
import { SeriesSuggestion } from "../SuggestionTypes/SeriesSuggestion/SeriesSuggestion";
import { AuthorSuggestion } from "../SuggestionTypes/AuthorSuggestion/AuthorSuggestion";
import { useNavigate } from "react-router-dom";


export const AddBookForm = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");
    // determining access level
    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let role = idAndRole.split(",")[1];

    const navigate = useNavigate();

    let timeOfBackendSearch = Date.now();
    let timeOfAPISearch = Date.now();

    const [existingInfoSearchResults, setExistingInfoSearchResults] = useState([]);

    const [file, setFile] = useState();

    const [searchInput, setSearchInput] = useSearchString();

    const [isLoading, setIsLoading] = useState(true); // if waiting on call to google/ol
    const [isAdding, setIsAdding] = useState(false); // if book is being added to db

    const [selectedSource, setSelectedSource] = useState("Open Library"); // choice of api to call. Options: google book API or Open Library
    const [optionBooks, setOptionBooks] = useState(); // books returned from api call
    const [selectedOption, setSelectedOption] = useState(null); // book that was clicked on 

    // states to catch changes to input feilds
    const [editingISBN, setEditingISBN] = useState("");
    const [editingTitle, setEditingTitle] = useState("");
    const [editingSeriesName, setEditingSeriesName] = useState("");
    const [editingSeriesNum, setEditingSeriesNum] = useState("");
    const [editingNumPages, setEditingNumPages] = useState("");
    const [editingPub, setEditingPub] = useState("");
    const [editingDescription, setEditingDescription] = useState("");

    const [editingAuthor, setEditingAuthor] = useState("");

    const [isRestricted, setIsRestricted] = useState(false);

    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedOtherAuthors, setSelectedOtherAuthors] = useState([]);

    const [duplicateSeriesNum, setDuplicateSeriesNum] = useState(false);
    const [badSubmit, setBadSubmit] = useState(false);

    useEffect(() => {
        // searches for books on first render and whenever the selected source changes
        setIsLoading(true);

        // resets selected option
        setSelectedOption(null);
        setEditingISBN("");
        setEditingTitle("");
        setEditingAuthor("");
        setEditingNumPages("");
        setEditingDescription("");
        setEditingSeriesName("")
        setEditingSeriesNum("")
        setEditingPub("")

        setExistingInfoSearchResults(null);

        if (searchInput !== "") {
            if (selectedSource === "Google") {
                getFromGoogle();
            } else {
                getFromOL();
            }
        } else {
            setOptionBooks(null);
            setIsLoading(false);
        }
    }, [selectedSource]);


    useEffect(() => {
        // gets work info from Open Library once option is selected
        // processes selected book option
        if (selectedOption !== null && selectedSource === "Open Library") {
            getMoreInfoFromOL();
        } else if (selectedOption !== null && selectedSource === "Google") {
            processGoogleOption();
        }

    }, [selectedOption]);

    useEffect(() => {
        // researches api(s) if search string changes
        let currentTime = Date.now();
        if ((currentTime - timeOfAPISearch) / 10 >= 0.2 && searchInput !== "") {
            timeOfAPISearch = Date.now();
            setIsLoading(true);

            // resets selected option
            setSelectedOption(null);
            setEditingISBN("");
            setEditingTitle("");
            setEditingAuthor("");
            setEditingNumPages("");
            setEditingDescription("");
            setEditingSeriesName("")
            setEditingSeriesNum("")
            setEditingPub("")

            if (selectedSource === "Google") {
                getFromGoogle();
            } else {
                getFromOL();
            }
        }
    }, [searchInput])


    // ----------------------------------------------------- PRE-RENDER

    // ------------------------------------------ OPEN LIBRARY

    async function getFromOL() {
        // gets possible works from Open Library
        var processedInput = searchInput.replace(/\s+/g, "+");

        await getBookFromOpenLibrary(processedInput, token)
            .then((foundJson) => {
                let processedBooks = JSON.parse(foundJson)
                if (processedBooks.docs.length === 0) {
                    setOptionBooks(<h2 className={styles.noOptions}>No Books Found</h2>);
                    setIsLoading(false);
                } else {
                    makeBookOptionObjects(processedBooks.docs)
                }
            })
            .catch((error) => {
                console.log("failed to get book options");
                console.log(error);
            })
    }

    async function getMoreInfoFromOL() {
        // gets edition information for selected book

        var proccessedWorkKey = selectedOption.key.split("/")[2]

        await getWorkInfoFromOpenLibrary(proccessedWorkKey, token)
            .then((foundWork) => {
                let processedWork = JSON.parse(foundWork)
                processOpenLibraryOption(processedWork)
            })
            .catch((error) => {
                console.log("failed to get work information");
                console.log(error);
            })
    }

    function processOpenLibraryOption(workInformation) {
        // gets relevent information out of Open Library API response

        if (workInformation?.isbn) {
            setEditingISBN(workInformation?.isbn)
        } else {
            // set isbn to fist english edition encountered
            for (let i = 0; i < selectedOption.isbn.length; i++) {
                if (selectedOption.isbn[i].length === 13 && (selectedOption.isbn[i].charAt(3) === 0 || selectedOption.isbn[i].charAt(3) === 1)) {
                    setEditingISBN(selectedOption.isbn[i])
                }
            }
        }
        setEditingTitle(workInformation?.title)
        searchForAuthor(false, selectedOption?.author_name[0], handleSelectingOtherAuthor);

        setEditingDescription(workInformation?.description?.value)
        if (workInformation?.subtite) {
            setEditingSeriesName(workInformation?.subtitle)
        }

        for (let i = 0; i < selectedOption.publish_date.length; i++) {
            if (selectedOption.publish_date[i].includes(selectedOption.first_publish_year)) setEditingPub(selectedOption.publish_date[i])
        }

    }

    // ------------------------------------------ GOOGLE

    async function getFromGoogle() {

        var processedInput = searchInput.replace(/\s+/g, "+");

        await getBooksFromGoogle(processedInput, token)
            .then((foundBooks) => {
                let processed = JSON.parse(foundBooks)
                if (processed.items.length === 0) {
                    setOptionBooks(<h2 className={styles.noOptions}>No Books Found</h2>);
                    setIsLoading(false);
                } else {
                    makeBookOptionObjects(processed.items)
                }
            })
            .catch((error) => {
                console.log("failed to get books information");
                console.log(error);
            })
    }

    function processGoogleOption() {
        // gets relevent information out of Google Book API response

        var isbn = ""
        if (selectedOption.industryIdentifiers !== null) {
            for (let i = 0; i < selectedOption.industryIdentifiers.length; i++) {
                // gets 13 digit isbn for english book
                if (selectedOption.industryIdentifiers[i].type === "ISBN_13" &&
                    (selectedOption.industryIdentifiers[i].identifier.charAt(3) == 0 || selectedOption.industryIdentifiers[i].identifier.charAt(3) == 1)) {
                    isbn = selectedOption.industryIdentifiers[i].identifier;
                }
            }
            setEditingISBN(isbn);
        }

        setEditingTitle(selectedOption.title);
        if (selectedOption.authors !== null) {
            searchForAuthor(false, selectedOption?.authors[0], handleSelectingOtherAuthor);
        }
        setEditingDescription(selectedOption?.description);
        setEditingNumPages(selectedOption?.pageCount);
        setEditingPub(selectedOption?.publishedDate);
    }

    // ------------------------------------------

    async function searchForSeries() {
        // searches existing series for possible match

        let searchObj = {
            searchString: editingSeriesName,
            searchType: "Series"
        }

        await getFromSearch(searchObj, token)
            .then((foundSeries) => {
                const temp = []
                for (let i = 0; i < foundSeries.length; i++) {
                    temp.push(<div className={styles.suggestion} key={i}><SeriesSuggestion seriesItem={foundSeries[i]} setSelectedSeries={setSelectedSeries} /></div>)
                }
                setExistingInfoSearchResults(temp)
            })
            .catch((error) => {
                console.log("failed to search for series");
                console.log(error)
            })
    }

    async function searchForAuthor(makeSuggestions, authorString, saveTo) {
        // searches existing authors for possible match
        let searchObj = {
            searchString: authorString,
            searchType: "Author"
        }

        await getFromSearch(searchObj, token)
            .then((foundAuthors) => {
                if (makeSuggestions) {
                    // if called to make suggestions
                    const temp = [];
                    for (let i = 0; i < foundAuthors.length; i++) {
                        temp.push(<div className={styles.suggestion} key={i}><AuthorSuggestion authorItem={foundAuthors[i]} setSelectedAuthor={saveTo} /></div>)
                    }
                    setExistingInfoSearchResults(temp);
                } else if (!makeSuggestions && foundAuthors.length === 1) {
                    // if called to search selected option provided string and only one result is found
                    saveTo(foundAuthors[0]);
                } else if (!makeSuggestions) {
                    // more than 1 or 0 results found from processing book selection
                    setEditingAuthor(authorString)
                }
            })
            .catch((error) => {
                console.log("failed to search for authors");
                console.log(error);
            })
    }

    async function postOtherAuthorsToDB() {
        // adds supporting authors

        var authors = [];
        for (let i = 0; i < selectedOtherAuthors.length; i++) {
            // goes through each author field

            if (selectedOtherAuthors[i].id === null) {
                // author not from suggestions
                let postModel = {
                    name: selectedOtherAuthors[i].name,
                    first: selectedOtherAuthors[i].first,
                    last: selectedOtherAuthors[i].last
                }

                await addAuthor(postModel, token)
                    .then((postedAuthor) => {
                        authors.push(postedAuthor)
                    })
                    .catch((error) => {
                        console.log("failed to post author")
                        console.log(error)
                    })
            }
            else {
                // otherwise add selected author
                authors.push(selectedOtherAuthors[i]);
            }
        }

        if (editingAuthor !== "") {
            // author name in input feild, + was not clicked, and suggestion not selected
            let splitName = editingAuthor.split(" ")
            let postModel = {
                name: editingAuthor,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" ")
            }

            await addAuthor(postModel, token)
                .then((postedAuthor) => {
                    authors.push(postedAuthor)
                })
                .catch((error) => {
                    console.log("failed to post author")
                    console.log(error)
                })
        }

        return authors;
    }

    async function postSeriesToDB() {
        // adds series to db if no selected series but a name has been input
        if (!selectedSeries && editingSeriesName !== "") {
            return await addSeries(editingSeriesName, token)
                .then((postedSeries) => {
                    return postedSeries;
                })
                .catch((error) => {
                    console.log("failed to post series")
                    console.log(error)
                })
        } else {
            return null;
        }
    }

    async function postBookToDB(authors, series) {
        // adds book to db

        let bookPostModel = {
            isbn: editingISBN,
            title: editingTitle,
            authors: [],
            seriesID: null,
            seriesNum: editingSeriesNum,
            pub: new Date(editingPub),
            cover: "https://covers.openlibrary.org/b/isbn/" + editingISBN + "-M.jpg",
            numPages: editingNumPages,
            description: editingDescription,
            restricted: isRestricted
        }

        for (let i = 0; i < authors.length; i++) {
            // gets author ids
            bookPostModel.authors.push(authors[i].id);
        }

        if (series !== null) {
            //new series posted
            bookPostModel.seriesID = series.id;
        } else if (selectedSeries !== null) {
            // existing series selected
            bookPostModel.seriesID = selectedSeries.id;
        }

        if (file !== undefined) {
            bookPostModel.cover = "aws";
        }
        else if (selectedSource === "Open Library") {
            // adds spesific book cover if sourse is OL
            bookPostModel.cover = `https://covers.openlibrary.org/b/id/${selectedOption.cover_i}-M.jpg`;
        }


        await addBook(bookPostModel, token)
            .then((postedBook) => {
                navigate(`/book/${postedBook.bookID}`);
            })
            .catch((error) => {
                console.log("failed to post book to database");
                console.log(error);
                setIsAdding(false);
            })
    }

    async function postCoverToAWS() {
        // adds cover to aws

        if (file !== null) {
            let newName = editingISBN + ".jpg";
            let formData = new FormData();
            formData.append(`file`, file, newName);

            await postBookCover(editingISBN, file, token)
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    console.log("failed to add cover");
                    console.log(error);

                    setIsAdding(false);
                })
            return true;
        }
    }

    let displayOptions = [
        <SmallDropdownItem key="google" displayValue="Google" onClick={() => handleSouceChange("Google")} />,
        <SmallDropdownItem key="ol" displayValue="Open Library" onClick={() => handleSouceChange("Open Library")} />
    ]

    function makeBookOptionObjects(possibleBooks) {
        // makes book objects for results of Open Library search

        let temp = [];
        const numBooks = possibleBooks.length > 6 ? 6 : possibleBooks.length;

        for (let i = 0; i < numBooks; i++) {
            if (selectedSource === "Google") {
                temp.push(<GoogleBook googleBookItem={possibleBooks[i].volumeInfo} setSelectedOption={setSelectedOption} key={i} />)
            } else {
                temp.push(<OpenLibraryBook openLibraryItem={possibleBooks[i]} setSelectedOption={setSelectedOption} key={i} />)
            }
        }

        setOptionBooks(temp)
        setIsLoading(false);
    }


    async function handleSubmit() {
        // post author and series, if necessary, then posts book

        if (duplicateSeriesNum ) {
            setBadSubmit(true)
        } else {
            setBadSubmit(false);
            setIsAdding(true);
            const [authors, series] = await Promise.all([
                postOtherAuthorsToDB(),
                postSeriesToDB(),
                postCoverToAWS()
            ]);
            postBookToDB(authors, series);
        }
    }

    // ------------------------------------------ STATE VAR CHANGE CATCHERS && RELATED FUNCTIONS

    function handleSouceChange(newSource) {
        // changes source of book options
        setSelectedSource(newSource);
    }

    const handleChangeIsbn = (event) => {
        // catchest changes to isbn 
        setEditingISBN(event.target.value);
        setExistingInfoSearchResults(null);
    }

    const handleChangeTitle = (event) => {
        // catches changes to title
        setEditingTitle(event.target.value);
        setExistingInfoSearchResults(null);
    }

    const handleChangeAuthor = (event) => {
        // catches changes to author
        setEditingAuthor(event.target.value);
        setExistingInfoSearchResults(null);

        // searches for existing authors if enough time has elapsed from last search
        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now();
            searchForAuthor(true, event.target.value, handleSelectingOtherAuthor);
        }
    }

    function handleRemoveOtherAuthorSelection(removedIndex) {
        // removes selected author
        let temp = []
        for (let i = 0; i<selectedOtherAuthors.length; i++) {
            if (i !== removedIndex) {
                temp.push(selectedOtherAuthors[i]);
            }
        }
        setSelectedOtherAuthors(temp);
    }

    function handleSelectingOtherAuthor(author) {
        // selects author from authorSuggestion

        setSelectedOtherAuthors(selectedOtherAuthors => [...selectedOtherAuthors, author]);
        setEditingAuthor("");
    }

    function handleAddUnknownAuthor() {
        // adds unselected author
        if (editingAuthor !== "") {
            let splitName = editingAuthor.split(" ");
            let authorItem = {
                id: null,
                name: editingAuthor,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" "),
                booksByAuthor: []
            }

            setSelectedOtherAuthors(selectedOtherAuthors => [...selectedOtherAuthors, authorItem]);
            setEditingAuthor("");
        }

    }

    const handleChangeSeriesName = (event) => {
        // catches changes to series name
        setEditingSeriesName(event.target.value);

        // searches for existing series if enough time has elapsed from last search
        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now();
            searchForSeries();
        }
    }

    function handleRemoveSeriesSelection() {
        // removes series selection 
        setSelectedSeries(null);
    }

    const handleChangeSeriesNum = (event) => {
        // catches changes to series number
        setEditingSeriesNum(event.target.value);
        setExistingInfoSearchResults(null);

        if (selectedSeries && selectedSeries.booksInSeries !== null) {
            // gets existing numbers from series
            let existingNumbers = []
            selectedSeries.booksInSeries.map((book) => {
                existingNumbers.push(book.seriesNum)
            })
            // checks that entered number has not already been used
            if (existingNumbers.includes(event.target.value)) {
                setDuplicateSeriesNum(true);
            }
            else {
                setDuplicateSeriesNum(false);
            }
        }

    }

    const handleChangeNumPages = (event) => {
        // catches changes to number of pages
        setEditingNumPages(event.target.value);
        setExistingInfoSearchResults(null);
    }

    const handleChangePub = (event) => {
        // catches changes to pub date
        setEditingPub(event.target.value);
        setExistingInfoSearchResults(null);
    }

    const handleChangeDescription = (event) => {
        // catches changes to description
        setEditingDescription(event.target.value);
        setExistingInfoSearchResults(null);
    }

    const handleChangeCover = (event) => {
        // catches changes to cover image
        setFile(event.target.files[0])
    }

    const handleChangeRestricted = () => {
        // catches changes to restricted checkbox
        setIsRestricted(!isRestricted)
    }


    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div>
                <div className={styles.header}>
                    <h1>Source: </h1>
                    <SmallDropdown buttonText={selectedSource} content={displayOptions} />
                </div>
                <div className={styles.options}>
                    {isLoading ? <div className={styles.loadingGif}><img src="/assets/images/loading.gif" alt="gif of a book flipping pages" /></div>
                        :
                        optionBooks
                    }
                </div>
            </div>
            <h2 className={styles.header}>Add your own info</h2>
            <div className={styles.inputFeilds}>

                <div className={styles.textFields}>
                    <div>
                        <h1 className={styles.label}>ISBN: </h1>
                        <input type="text" placeholder="13 digit" className={styles.input} value={editingISBN} onChange={handleChangeIsbn} />
                    </div>
                    <div>
                        <h1 className={styles.label}>Title:</h1>
                        <input type="text" className={styles.input} value={editingTitle} onChange={handleChangeTitle} />
                    </div>

                    <div>
                        <h1 className={styles.label}>Author(s): </h1>
                        {selectedOtherAuthors.map((author, index) =>
                            <div className={styles.selection} key={"selected author" + index}>
                                <AuthorSuggestion authorItem={author} />
                                <img className={styles.removeSelection} onClick={() => handleRemoveOtherAuthorSelection(index)} alt="remove button"/>
                            </div>
                        )}

                        <div className={styles.authorAndAdd}>
                            <input type="text" key="authorInput" className={styles.shortInput} value={editingAuthor} onChange={handleChangeAuthor} />
                            <button className={styles.addButton} onClick={handleAddUnknownAuthor} alt="add button"> + </button>
                        </div>
                    </div>

                    <div>
                        <h1 className={styles.label}>Series: </h1>
                        {selectedSeries ?
                            <div className={styles.selection} key="selected series"><SeriesSuggestion seriesItem={selectedSeries} /> <img className={styles.removeSelection} onClick={handleRemoveSeriesSelection} alt="remove button"/></div>
                            :
                            <input type="text" className={styles.input} value={editingSeriesName} onChange={handleChangeSeriesName} />}
                    </div>

                    <div>
                        <h1 className={styles.label}>Series Number: </h1>
                        {duplicateSeriesNum ? <h5 className={styles.duplicateSeriesNum}>There is already a book in that slot</h5> : null}
                        {isNaN(editingSeriesNum) ? <h5 className={styles.duplicateSeriesNum}>Series number must be a valid number</h5> : null}
                        {/* {editingSeriesName !== "" && editingSeriesNum === "" ? <h5 className={styles.duplicateSeriesNum}>You've selected a series but not included the number</h5> : null} */}
                        <input type="text" className={styles.input} value={editingSeriesNum} onChange={handleChangeSeriesNum} />
                    </div>

                    <div>
                        <h1 className={styles.label}>Number of Pages: </h1>
                        <input type="text" className={styles.input} value={editingNumPages} onChange={handleChangeNumPages} />
                    </div>

                    <div>
                        <h1 className={styles.label}>Publication Date: </h1>
                        <input type="text" className={styles.input} value={editingPub} onChange={handleChangePub} />
                    </div>

                    <div>
                        <h1 className={styles.label}>Description: </h1>
                        <Textarea type="text" autoSize={{ minRows: 5 }} className={styles.input} value={editingDescription} onChange={handleChangeDescription} />
                    </div>

                    <div className={styles.cover}>
                        <h1 className={styles.label}>Cover: </h1>
                        <input id="input" type="file" accept=".jpg" className={styles.fileSelector} onChange={handleChangeCover}/>
                    </div>

                    {
                        role === "admin" ?
                        <div>
                            <input type="checkbox" id="restricted" name="restricted" onChange={handleChangeRestricted}/>
                            <label htmlFor="restricted" className={styles.labelCheck}> Restricted</label>
                        </div>
                        :
                        null
                    }

                    <div>
                        {badSubmit ? <h5 className={styles.duplicateSeriesNum}>Something doesn't look quite right</h5> : null}
                        {isAdding ?
                            <div className={styles.loadingGif}><img src="/assets/images/loading.gif" alt="gif of a book flipping pages" /></div>
                            :
                            <button className={badSubmit ? styles.badSubmitButton : styles.submitButton} onClick={handleSubmit}>Submit Book</button>
                        }
                    </div>

                </div>
                <div className={styles.suggestions}>
                    {existingInfoSearchResults}
                </div>
            </div>
        </section>
    );
}