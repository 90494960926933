import React, { useState } from "react";
import styles from "./SearchBar.module.css";

import { getFromSearch, getFromSearchAllAccess } from "../../api/Api";

import { useSearch, useSearchString } from "../../contexts/SearchContext";

export const SearchBar = (props) => {
    //props:
    //
    //setSearchResults - function to save search results in state of parent element
    //setIsOpen - set state var function from parent

    // ----------------------------------------------------- STATES AND HOOKS

    let token = localStorage.getItem("token");

    const [searchInput, setSearchInput] = useSearchString();
    const [searchType, activeIndex] = useSearch();

    let timeOfSearch = Date.now();

    // ----------------------------------------------------- PRE-RENDER

    const handleChange = (event) => {
        //catches changes to seach input
        setSearchInput(event.target.value); 
        
        let currentTime = Date.now(); 
        if ( ((currentTime-timeOfSearch)/1000 >= 0.2)  && (event.target.value !== "") && (event.target.value !== " ") ) { 
            //ensures api is not called too many times, for an empty string, or for just a space
            timeOfSearch = Date.now(); //reset time of last search
            fetchBooks(event.target.value);
            props.setIsOpen(true);

        } else if (event.target.value === "") {
            props.setSearchResults([]);
            props.setIsOpen(false);
        }

    }

    async function fetchBooks(value){
        //gets book from db
        const searchObj = {
            searchString : value, 
            searchType: searchType
        }


        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getFromSearchAllAccess(searchObj, token)
            .then((results) => { 
                props.setSearchResults(results);
                if (results.length > 0 ) { //opens results if any found
                    props.setIsOpen(true);
                }
            })
            .catch((error) => {
                console.log("failed to find books from search");
                console.log(error);
            })
        } else {
            // limited access
            await getFromSearch(searchObj, token)
            .then((results) => { 
                props.setSearchResults(results);
                if (results.length > 0 ) { //opens results if any found
                    props.setIsOpen(true);
                }
            })
            .catch((error) => {
                console.log("failed to find books from search");
                console.log(error);
            })
        }


    }

    // ----------------------------------------------------- RENDER
    return (
        <div className={styles.container}>
            <img src="/assets/images/searchIcon.svg" className={styles.icon}/>
            <input className={styles.input}
                   onChange={handleChange}
                   value={searchInput}
                   placeholder="Type to search..."/>
        </div>
    );
}