//api.js

const SERVER_URL = 'https://earmarked.app/api/v0/readingtracker';
// const SERVER_URL = 'http://localhost:8080/api/v0/readingtracker';

// ------------------------------------------------------------------------- BOOKS
async function getBooks(library, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/books?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get books: ${error.message}`);
    }
}

async function getBooksAllAccess(library, token) {
    // all access
    try {
        const response = await fetch(`${SERVER_URL}/books/allaccess?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get books: ${error.message}`);
    }
}

async function getBook(bookID, token) {
    // limited access
    try{
        const response = await fetch(`${SERVER_URL}/books/${bookID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get book: ${error.message}`)
    }
}

async function getBookAllAccess(bookID, token) {
    // all access
    try{
        const response = await fetch(`${SERVER_URL}/books/${bookID}/allaccess`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get book: ${error.message}`)
    }
}

async function getBookPage(pageNumber, pageSize, library, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/books/page?pageNumber=${pageNumber}&pageSize=${pageSize}&library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }

}

async function getBookPageAllAccess(pageNumber, pageSize, library, token) {
    // all access
    try {
        const response = await fetch(`${SERVER_URL}/books/page/allaccess?pageNumber=${pageNumber}&pageSize=${pageSize}&library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }

}

async function getTotalItems(library, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/books/totalItems?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }
}

async function getTotalItemsAllAccess(library, token) {
    // all access
    try {
        const response = await fetch(`${SERVER_URL}/books/totalItems/allaccess?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }
}

async function patchBook(bookID, bookUpdates, token) {
    try {
        const response = await fetch(`${SERVER_URL}/books/${bookID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(bookUpdates)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }
}

async function addToLibrary(bookID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/userbooks`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: bookID
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post tag: ${error.message}`)
    }
}

async function addBook(bookPostModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/books`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(bookPostModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post book: ${error.message}`)
    }
}

async function deleteUserbook(userbookID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/userbooks/${userbookID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to delete userbook: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- COVERS

async function getBookCover(isbn, token) {
    try {
        const response = await fetch(`${SERVER_URL}/bookCovers/${isbn}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }
}

async function postBookCover(isbn, image, token) {
    try {
        const response = await fetch(`${SERVER_URL}/bookCovers/${isbn}`, {
            method: 'POST',
            headers: {'Content-Type': 'image/jpeg', "Authorization": `Bearer ${token}`}, 
            body: image
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post book cover: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- AUTHOR
async function getAuthor(id) {
    try{
        const response = await fetch(`${SERVER_URL}/authors/${id}`, {
            method: 'GET',
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get author: ${error.message}`)
    }
}

async function getAuthorBooks(id, token) {
    try{
        const response = await fetch(`${SERVER_URL}/authors/${id}/books`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get author: ${error.message}`)
    }
}

async function addAuthor(postModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/authors`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(postModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post author: ${error.message}`)
    }

}

// ------------------------------------------------------------------------- SERIES

async function getAllSeries(token) {
    try{
        const response = await fetch(`${SERVER_URL}/series`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get all series: ${error.message}`)
    }
} 

async function getOneSeries(id) {
    try{
        const response = await fetch(`${SERVER_URL}/series/${id}`, {
            method: 'GET',
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get author: ${error.message}`)
    }

}

async function getBooksInSeries(id) {
    try{
        const response = await fetch(`${SERVER_URL}/series/${id}/books`, {
            method: 'GET',
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get works in series: ${error.message}`)
    }
}

async function addSeries(seriesName, token) {
    try {
        const response = await fetch(`${SERVER_URL}/series`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: seriesName
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post series: ${error.message}`)
    }
}


// ------------------------------------------------------------------------- TAGS
async function getTags(token) {
    try {
        const response = await fetch(`${SERVER_URL}/tags/all`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get tags: ${error.message}`)
    }
}

async function postTag(tagValue, token) {
    try {
        const response = await fetch(`${SERVER_URL}/tags`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: tagValue
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post tag: ${error.message}`)
    }
}

async function putTag(bookID, id, token) {
    try {
        const response = await fetch(`${SERVER_URL}/books/${bookID}/tags/${id}`, {
            method: "PUT",
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to put tag: ${error.message}`)
    }
}

async function removeTagFromBook(bookID, tagToRemove, token) {
    try {
        const response = await fetch(`${SERVER_URL}/books/${bookID}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`},
            body: tagToRemove
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

async function deleteTag(id, token) {
    try {
        const response = await fetch(`${SERVER_URL}/tags/${id}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- READINGS

async function postReading(reading, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(reading)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function endReading(readingID, readingEndModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/end/${readingID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(readingEndModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to end reading: ${error.message}`)
    }    
}

async function patchReading(readingID, readingPatchModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/${readingID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(readingPatchModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch reading: ${error.message}`)
    }    
}

async function deleteReading(readingID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/${readingID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- SEARCH

async function getFromSearch(search, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/search`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}


async function getFromSearchAllAccess(search, token) {
    // all access
    try {
        const response = await fetch(`${SERVER_URL}/search/allaccess`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}

async function searchLibrary(search, token) {
    try {
        const response = await fetch(`${SERVER_URL}/search/library`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- ENTRIES

async function getEntriesForBook(bookID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${bookID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get entry: ${error.message}`)
    }

}

async function postEntry(entry, bookID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${bookID}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(entry)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find entry: ${error.message}`)
    }
}

async function patchEntry(entryPatchModel, entryID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${entryID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(entryPatchModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch entry: ${error.message}`)
    }
}

async function deleteEntry(entryID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${entryID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove entry: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- RECOMMENDATIONS

async function postRecommendation(recommendationPostModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/recommendations`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(recommendationPostModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post recommendation: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- LOGIN

async function postLogin(credentials) {
    try {
        const response = await fetch(`${SERVER_URL}/sessions`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify(credentials)
        });
        return handleResponse(response);
    } catch (error) {
        console.log(error);
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function postNewUser(credentials) {
    try {
        const response = await fetch(`${SERVER_URL}/users`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify(credentials)
        });
        return handleResponse(response);
    } catch (error) {
        console.log(error);
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function getUser(userID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/users/${userID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get user: ${error.message}`)
    }
}

async function verifyToken(token) {
    try {
        const response = await fetch(`${SERVER_URL}/users/verification`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed verify token: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- OPEN LIBRARY

async function getBookFromOpenLibrary(input, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/openlibrary?searchString=${input}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get book from open library: ${error.message}`)
    }
}

async function getWorkInfoFromOpenLibrary(OLWorkID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/openlibrary/${OLWorkID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get work from open library: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- GOOGLE BOOKS

async function getBooksFromGoogle(input, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/google?searchString=${input}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get books from google: ${error.message}`)
    }
}


// ------------------------------------------------------------------------- HANDELER

async function handleResponse(response) {
    if(!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`)
    }
    const responseType = response.headers.get('content-type');

    switch (responseType) {
        case 'application/json':
            return response.json();
        case 'text/plain;charset=UTF-8':
            return response.text();
        case 'application/json; charset=UTF-8':
            // return type of google books API
            return response.json();
        case `application/octet-stream`:
            return response;
        default:
            return null;
    }
}

export { getBooks, getBook, getBookPage, getTotalItems, patchBook, addToLibrary, addBook, deleteUserbook,
        getBooksAllAccess, getBookAllAccess, getBookPageAllAccess, getTotalItemsAllAccess,
        getBookCover, postBookCover,
        getFromSearch, searchLibrary,
        getFromSearchAllAccess,
        getAuthor, getAuthorBooks, addAuthor,
        getAllSeries, getOneSeries, getBooksInSeries, addSeries,
        getTags, putTag, postTag, removeTagFromBook, deleteTag, 
        postReading, endReading, patchReading, deleteReading,
        getEntriesForBook, postEntry, patchEntry, deleteEntry, 
        postRecommendation, 
        postLogin, getUser, postNewUser, verifyToken,
        getBookFromOpenLibrary, getWorkInfoFromOpenLibrary,
        getBooksFromGoogle
    };