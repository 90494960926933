
import React, { useEffect, useState } from "react";
import styles from "./AuthorBanner.module.css";

import { AuthorWorkBook } from "../BookTypes/WorkBooks/AuthorWorkBook";

export const AuthorBanner = (props) => {

    // props:
    //
    // authorItem - object containing author id and name

    // ----------------------------------------------------- STATE AND HOOKS

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => { // avoids flash of 'undefined' for title
        if (Object.keys(props.authorItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [props.authorItem]);

    // ----------------------------------------------------- PRE-RENDER

    let workObjects = [];
    let authorNameDisplay = props.authorItem.last + ", " + props.authorItem.first

    if (props.authorItem.booksByAuthor) {
        // sorts book by series then series num
        let sortedWorks = props.authorItem.booksByAuthor.sort(function (a, b) {
            return a.series?.id - b.series?.id || a.seriesNum - b.seriesNum;
        })

        // makes book objects
        for (let i = 0; i < sortedWorks.length; i++) {
            workObjects.push(<div key={i}><AuthorWorkBook bookItem={sortedWorks[i]} /></div>);
        }
    }

    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.authorName}>{authorNameDisplay}</h1>
                    <div className={styles.workContainer}>{workObjects}</div>
                </div>

            </div>
        )
    }
}