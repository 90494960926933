import React, { useEffect, useState } from "react";
import styles from "./AccountInfo.module.css";

export const AccountInfo = (props) => {

    // props:
    // 
    // userItem - object containing user info

    // ----------------------------------------------------- STATE AND HOOKS

    const [usernameTracker, setUsernameTracker] = useState("");
    const [emailTracker, setEmailTracker] = useState("");

    useEffect(() => {
        // sets tracker once props arrive
        if (props.userItem) {
            setUsernameTracker(props.userItem.username);
            setEmailTracker(props.userItem.email);
        }
    },[props.userItem])
    // ----------------------------------------------------- PRE-RENDER

    function handleUsernameChange(event){
        // catches changes to username feild
        setUsernameTracker(event.target.value);
    }

    function handleEmailChange(event) {
        // catches changes to email feild
        setEmailTracker(event.target.value);
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h1 className={styles.sectionTitle}>Account Details</h1>

                <div className={styles.accountDetails}>
                    <h2 className={styles.subTitle}>Username</h2>
                    <div className={styles.input}>{props.userItem.username}</div>

                    <h2 className={styles.subTitle}>Email</h2>
                    <div className={styles.input}>{props.userItem.email}</div>

                    {/* <button type="submit" 
                            className={styles.submitButton}>
                            Submit Changes
                    </button> */}
                </div>
                
            </div>
           
        </section>
    )
}