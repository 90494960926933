import React from "react";
import styles from "./DropdownItem.module.css";

export const DropdownItem = (props) => {

    //props:
    //
    //displayValue - string, human friendly status
    //onClick - function, switches selected library

    return (
        <div className={styles.dropdownItem} onClick={props.onClick}>
            {props.displayValue}
        </div>
    );

}