import React, { useRef, useEffect } from "react";
import styles from "./MarkdownInfo.module.css";

export const MarkdownInfo = (props) => {

    // props:
    // 
    // toggle - state var setter function to toggle if markdown info is visiable
    // isOpen - state var if markdown info is currently visable

    // ----------------------------------------------------- STATE AND HOOKS

    const markdownInfoRef = useRef();

    useEffect(() => { //closes dropdown when somewhere else is clicked
        const handler = (event) => {
            //closes if somewhere else is clicked
            if(markdownInfoRef.current && (!markdownInfoRef.current.contains(event.target) && event.target.id !== "InfoIcon")) {
                props.toggle(false)
            }
        }
        document.addEventListener("click", handler);

        return () => { //cleans up listener
            document.removeEventListener("click", handler)
        }

    }, [markdownInfoRef]);

    // ----------------------------------------------------- PRE-RENDER

    let carrotString = ">"

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} ref={markdownInfoRef}>
            <h3 className={styles.header}>Markdown Notation: </h3>
            <div className={styles.content}>
                <div># : header 1</div>
                <div>## : header 2</div>
                <div>### : header 3</div>
                <div>#### : header 4</div>
                
                <div>*text* : italics</div>
                <div>**text** : bold</div>
                <div> {carrotString} text : block quote</div>
            </div>
        </section>
    )
}