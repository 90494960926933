import React, { useState, useEffect } from "react";

import styles from "./TagEditor.module.css";

import { getTags, putTag, postTag, removeTagFromBook } from "../../api/Api";

import { Tag } from "../Tag/Tag";
import { useBook } from "../../contexts/BookContext";

export const TagEditor = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const bookItem = useBook();

    let token = localStorage.getItem("token");

    const [isEditingTag, setIsEditingTag] = useState(false);
    const [thisBookTags, setThisBookTags] = useState([]);
    const [allTags, setAllTags] = useState([]);

    const [showError, setShowError] = useState(null);

    useEffect(() => {
        fetchTags();
    }, [])

    useEffect(() => { //sets this books tags once props arrives
        if (bookItem && bookItem.tags) { setThisBookTags(bookItem.tags) }
        else {setThisBookTags([])} // clears tags if search to book not in library from book page 
    }, [bookItem.tags])

    // ----------------------------------------------------- PRE-RENDER

    const updateTagEditing = () => {
        //toggles tag edditng box
        setShowError(null)
        setIsEditingTag(!isEditingTag)
    }

    function addTag(event) {
        //adds tag to backend
        if (event.key === "Enter" && event.target.value !== "") { // if user intentionally sends
            setShowError(null);
            if (event.target.value.length > 50) { // doesn't allow adding of tag more than 50 characters
                // alert("Body of tag is too long, please try again");
                setShowError("Body of tag is too long, please try again")
            } else {
                // checks to see if book already has tag
                let bookContains = false;
                thisBookTags.map((tag) => {
                    if (tag.tagValue === event.target.value) {
                        bookContains = true;
                        setShowError("That tag is already associated with this book.")
                    }
                });

                // checks to see if any book already has tag
                let allContains = false; 
                let tagId = 0;
                allTags.map((tag) => {
                    if (tag.tagValue === event.target.value) {
                        allContains = true;
                        tagId = tag.id
                    }
                });

                if ( allContains && !bookContains ) { // if found tag in allTags, adds it to book in backend
                    addTagToBook(tagId);
                } else if ( !allContains ) { // entirly new tag, add it to tag table and then to spesific book
                    addTagToTags(event.target.value);
                }
                
                event.target.value = "";
            }
        }
    }

    function handleRemoveTag(tag) {
        //handles a click to the x button of a tag
        setShowError(null)
        removeTagFromDB(tag.id);
    }

    // ---------------------------- FUNCTIONS THAT CONNECT TO BACKEND

    async function fetchTags() {
        //gets all tags
        await getTags(token)
            .then((tags) => {
                setAllTags(tags);
            })
            .catch((error) => {
                console.log("failed to get tags")
                console.log(error)
            })
    }

    async function addTagToBook(tagId) {
        //attaches a tag to a book resource by the tag's id 
        await putTag(bookItem.userbookID, tagId, token)
            .then((book) => {
                setThisBookTags(book.tags);
            })
            .catch((error) => {
                console.log("failed to add tag to book");
                console.log(error.message);

                if (error.message == "Request failed with status: 400") {
                    setShowError("That tag is already associated with this book");
                }
            })
    }

    async function addTagToTags(tagValue) {
        await postTag(tagValue, token) // adds a new tag to the tag resource collection
            .then((tag) => {
                addTagToBook(tag.id); // attaches the new tag to the book
                setAllTags([...allTags,  tag]); // adds new tag to total tags
            })
            .catch((error) => {
                console.log("failed to add tag to tags");
                console.log(error.message)
            })
    }

    async function removeTagFromDB(tagId) {
        //removes tag from stored book
        await removeTagFromBook(bookItem.userbookID, tagId, token)
            .then (
                setThisBookTags([...thisBookTags.filter(tag => tag.id !== tagId)])
            )
            .catch((error) => {
                console.log("failed to remove tag from database")
                console.log(error)
            })

    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.tagContainer}>
            <div className={styles.titleAndButton}>
                <h3 className={styles.infoTitle}>Tags</h3>
                <button
                    className={styles.editButton}
                    onClick={updateTagEditing}>
                    <img src="/assets/images/editIcon.svg"
                        className={styles.editButtonImage}
                        alt="Edit Icon" />
                </button>
            </div>
            <div className={styles.directTagContainer}>
                <ul className={styles.tagList}>
                    {thisBookTags.map((tag, index) =>
                        <li key={index} className={styles.tag}>
                            <Tag tagItem={tag} deleteButtonClick={handleRemoveTag} isEditingTag={isEditingTag} />
                        </li>
                    )}
                </ul>
                {isEditingTag ? <div>
                    <h5 className={styles.errorMessage}>{showError}</h5>
                    <input  className={styles.tagInput} key="tag input"
                            type="text"
                            autoFocus={true}
                            list='tagSuggestion'
                            placeholder="Press enter to add tag"
                            onKeyUp={event => addTag(event)} />
                    <datalist id="tagSuggestion">
                        {allTags.map((tag, index) => {
                            return (<option key={index} value={tag.tagValue}>{tag.tagValue}</option>)
                        })}
                    </datalist>
                </div>
                    :
                    null
                }
            </div>
        </div>
    );
}