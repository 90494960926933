import React, { useEffect, useState } from "react";
import styles from "./RecommendationBook.module.css";
import { Cover } from "../../Cover/Cover";
import { getBook, getBookAllAccess } from "../../../api/Api";
import { Author } from "../../Author/Author";
import { Link } from "react-router-dom";

export const RecommendationBook = (props) => {

    // props:
    // 
    // recItem - object containing recommendation book information

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    
    let bookPath = "/book/" + props.recItem.bookB.bookID;

    const [recBook, setRecBook] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // gets recommended book on first render
        fetchBook();
    },[])
    // ----------------------------------------------------- PRE-RENDER

    async function fetchBook() {
        // gets book
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBookAllAccess(props.recItem.bookB.bookID, token)
                .then((foundBook) => {
                    setRecBook(foundBook);
                    // formatAuthors(foundBook);
                    setIsLoaded(true)
                })
                .catch((error) => {
                    console.log("failed to fetch recommended book");
                    console.log(error);
                });
        } else {
            // limited access
            await getBook(props.recItem.bookB.bookID, token)
                .then((foundBook) => {
                    setRecBook(foundBook);
                    // formatAuthors(foundBook);
                    setIsLoaded(true)
                })
                .catch((error) => {
                    console.log("failed to fetch recommended book");
                    console.log(error);
                });
        }
    }

    // ----------------------------------------------------- RENDER
    if (isLoaded) {
        return (
            <section className={styles.container}>
                <Cover  bookItem = {recBook} size={window.screen.width > 500 ? "L" : "S"} loc={"bookRec:"+props.recItem.recommendationID}/>
                <div className={styles.bookInfoAndNote}>
                    <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{recBook.title}</h1></Link>
                    <Author authorsList={recBook.authors} size={1} link={true}/>
                    <div className={styles.note}>{props.recItem.note}</div>
                </div>
                
            </section>
        )
    } else {
        // not fetched recommended book yet
        return (
            null
        )
    }

}