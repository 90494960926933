import React, { useState } from "react";
import styles from "./ProgressUpdater.module.css";

import ProgressBar from "@ramonak/react-progress-bar";

import { patchBook } from '../../api/Api';

export const ProgressUpdater = (props) => {

    // props:
    // 
    // bookItem - object containing book infromation
    // bookUpdator - updates book 

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token"); 

    const [pageItirator, setPageItirator] = useState(props.bookItem.progress); //update current page

    let progressValue = Math.round((props.bookItem.progress / props.bookItem.numPages) * 100); // inial progress value

    const [progressCheck, setProgressCheck] = useState(progressValue > 20); //Where to place progress bar lable, inside or outside bar


    async function savePageChanges() {
        //sends updated data to backend
        if (pageItirator > props.bookItem.numPages ) { //sets to max pages if new page num is larger than pages in book
            setPageItirator(props.bookItem.numPages);
            updateBookProgress({progress: props.bookItem.numPages})

        } else if (pageItirator < 0 ) { //sets to 0 if new page num is less than 0
            setPageItirator(0);
            updateBookProgress({progress: 0})
            
        } else { //sets new page num
            updateBookProgress(pagesUpdatesObj)
        }

        // checks if progress % should be inside bar or out
        if (Math.round((pageItirator / props.bookItem.numPages) * 100) > 20) {
            setProgressCheck(true);
        } else {
            setProgressCheck(false);
        }
    }
    // ----------------------------------------------------- PRE-RENDER

    const itiratePage = (event) => {
        //catches changes to page number
        setPageItirator(event.target.value);
    }
    
    const pagesUpdatesObj = {
        //collects data to be sent to backend
        progress: parseInt(pageItirator)
    }

    const handleKeyDown = (e) => {
        //sends page to database if 'enter' is pressed
        if (e.keyCode === 13) {
            savePageChanges();
        }
    }

    async function updateBookProgress(updateValue) {

        await patchBook(props.bookItem.userbookID, updateValue, token)
            .then((updatedBook) => {
                props.bookUpdater(updatedBook);
            })
            .catch((error) => {
                console.log("failed to update reading progress")
                console.log(error)
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {progressCheck ? (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#58989D"
                            labelColor="#fff"
                            labelAlignment="right"
                        />
                    ) : (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#58989D"
                            labelColor="#2D4C4E"
                            labelAlignment="outside"
                        />
            )}
            <div className={styles.pageItiratorAndSave}>
                <input name="Page"
                    value={pageItirator}
                    onChange={itiratePage}
                    placeholder={props.bookItem.progress}
                    inputMode="numeric"
                    type="number"
                    max={props.bookItem.numPages}
                    className={styles.inputBox}
                    onKeyDown={handleKeyDown}
                />
                <button className={styles.saveButton}
                    type="submit"
                    onClick={savePageChanges}
                >Save
                </button>
            </div>
        </section>
    )
}