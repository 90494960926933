import React from "react";
import styles from "./OpenLibraryBook.module.css";

export const OpenLibraryBook = (props) => {

    // props:
    // 
    // openLibraryItem - object containing open library response
    // setSelectedOption - state var setter from parent

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    //console.log("openLibrayItem: ",props)
    let firstISBN = "";
    if (props.openLibraryItem.isbn) {
        firstISBN = props.openLibraryItem.isbn[0];
    }
    else {
        firstISBN = 0;
    }
    let coverPath = `https://covers.openlibrary.org/b/id/${props.openLibraryItem.cover_i}-M.jpg`;


    function handleClick() {
        // sends click back to parent
        props.setSelectedOption(props.openLibraryItem);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={handleClick}>
            <div className={styles.content}>
                {firstISBN != 0 ? 
                    <img className={styles.bookCover} src={coverPath} /> 
                    :
                    <div className={styles.noCover}> No Cover Avaliable </div>
                }
                <div className={styles.bookInfo}>
                    <div className={styles.bookTitle}>{props.openLibraryItem.title}</div>
                    {props.openLibraryItem?.author_name ? <div className={styles.bookAuthor}>{props.openLibraryItem?.author_name[0]}</div> : null}
                </div>
            </div>
        </section>
    );
}