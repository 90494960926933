import React, { useEffect } from "react";
import styles from "./AuthorSuggestion.module.css";

export const AuthorSuggestion = (props) => {

    // props:
    // 
    // authorItem - object containing author information
    // setSelectedAuthor - state var setter to let parent know about click

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    const authorDisplayString = props.authorItem.last + ", " + props.authorItem.first;
    let booksContainedIn = [];

    if (props.authorItem.booksByAuthor) {
        for (let i = 0; i < props.authorItem.booksByAuthor.length; i++) {
            booksContainedIn.push(<h5 key={i} className={styles.booksByAuthor}>{props.authorItem.booksByAuthor[i].title}</h5>)
        }
    }
    
    function handleClick() {
        // handles click if object is clickable
        if (props.setSelectedAuthor) props.setSelectedAuthor(props.authorItem);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={handleClick}>
            <div className={styles.content}>
                <div className={styles.authorInfo}>
                    <h2 className={styles.authorName}>{authorDisplayString}</h2>
                    {booksContainedIn}
                </div>
            </div>
        </section>
    )
}