import React, { useState, useContext, createContext } from "react";

export const BookContext = createContext(null);
export const BookUpdateContext = createContext(null);

export function useBook() {
    //allows reading of book data
    const book = useContext(BookContext);
    if (book === undefined) {
        console.log("There has been an error, check BookContext.js");
    }
    return book;
}

export function useBookUpdate() {
    //allows writing of book data
    const bookUpdate = useContext(BookUpdateContext);
    if (bookUpdate == undefined){
        console.log("There has been an error, check BookContext.js");
    }
    return bookUpdate
}

export function BookContextProvider( {children} ) {

    const [bookItem, setBookItem] = useState({});

    const setBook = (book) => {
        //sets book item
        setBookItem(book)
    }
    
    return (
        <BookContext.Provider value={bookItem} >
            <BookUpdateContext.Provider value={setBook}>
                {children} 
            </BookUpdateContext.Provider>
        </BookContext.Provider>
    );
}