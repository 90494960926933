import React, { useEffect, useState } from "react";
import styles from "./SeriesBanner.module.css";

import { SeriesWorkBook } from "../BookTypes/WorkBooks/SeriesWorkBook";
import { useBookUpdate } from "../../contexts/BookContext"

export const SeriesBanner = (props) => {
    
    // props:
    //
    // seriesItem - object containing series id and name

    // ----------------------------------------------------- STATE AND HOOKS

    const [isLoaded, setIsLoaded] = useState(false);
    const updateBook = useBookUpdate();

    useEffect(() => { // prevents loading before data has arrived
        if (Object.keys(props.seriesItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [props.seriesItem])

    useEffect(() => { // clears book to prevent flash of pervious one
        updateBook({});
    }, [])

    // ----------------------------------------------------- PRE-RENDER

    let workObjects = [];

    if (props.seriesItem.booksInSeries && props.seriesItem.booksInSeries.length != 0) {
        // sorts books by series number
        let sortedSeriesBooks = props.seriesItem.booksInSeries.sort(function (a, b) {
            if (a.seriesNum > b.seriesNum) {
                return 1;
            }
            if (a.seriesNum < b.seriesNum) {
                return -1;
            }
            return 0;
        })

        // makes book objects
        for (let i = 0; i < sortedSeriesBooks.length; i++) {
            workObjects.push(<div key={i}><SeriesWorkBook bookItem={sortedSeriesBooks[i]} /></div>);
        }
    }

    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.seriesName}>{props.seriesItem.name}</h1>
                    <div className={styles.workContainer}>{workObjects}</div>
                </div>
            </div>
        );
    }
}