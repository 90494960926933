import React, { useState } from "react";
import styles from "./NewRecommendation.module.css";

import Textarea from 'rc-textarea';

import { getFromSearch, postRecommendation, searchLibrary } from "../../api/Api";
import { BookSuggestion } from "../SuggestionTypes/BookSuggestion/BookSuggestion";
import { SelectedBookSuggestion } from "../SuggestionTypes/BookSuggestion/SelectedBookSuggestion";
import { useBook } from "../../contexts/BookContext";

export const NewRecommendation = (props) => {

    // props:
    // 
    // setIsAdding - state var setter to close new recs
    // setNewRecommendation - state var setter to trigger posting of new rec



    // NOTE: Can ONLY create recommendations for books in user's library

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");
    let timeOfBackendSearch = Date.now();

    let bookItem = useBook();

    const [bookBTracker, setBookBTracker] = useState("");
    const [noteTracker, setNoteTracker] = useState("");
    const [reciprical, setRecprical] = useState(false);

    const [suggestions, setSuggestions] = useState([]); // search options

    const [selectedBook, setSelectedBook] = useState(null); // book chosen from search options

    const [errorMessage, setErrorMessage] = useState(null);


    // ----------------------------------------------------- PRE-RENDER

    const changeBookB = (event) => {
        // catches changes to book B search
        setBookBTracker(event.target.value);

        // searches for existing series if enough time has elapsed from last search
        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now();
            searchForTitle(event.target.value);
        }
    }

    const changeNote = (event) => {
        // catches changes to note
        setNoteTracker(event.target.value);
    }

    const removeSelection = () => {
        // removes selected book

        setSelectedBook(null);
        setErrorMessage(null);
    }

    const changeReciprical = () => {
        // handles change to recprical
        setRecprical(!reciprical);
    }

    async function searchForTitle(inputString) {
        // searches back for possible match

        if (selectedBook !== null) {
            return;
        }

        let searchObj = {
            searchString: inputString,
            searchType: "Title"
        }

        await searchLibrary(searchObj, token)
            .then((foundBooks) => {
                const temp = []
                for (let i = 0; i < foundBooks.length; i++) {
                    temp.push(<div className={styles.suggestion} key={i}><BookSuggestion bookItem={foundBooks[i]} setSelectedBook={setSelectedBook} /></div>)
                }
                setSuggestions(temp)
            })
            .catch((error) => {
                console.log("failed to search for series");
                console.log(error)
            })
    }

    function submitRec() {
        // sends rec to back
        setErrorMessage(null)

        if (selectedBook === null) {
            // checks that a book was selected to recommend
            setErrorMessage(<div className={styles.errorMessage}>No book was selected to recommend</div>)
            return;
        }

        // checks to make sure selected book has not already been recommended
        for (let i = 0; i < bookItem.recommendations.length; i++) {
            if (bookItem.recommendations[i].bookB.bookID === selectedBook.bookID) {
                setErrorMessage(<div className={styles.errorMessage}>That book as already been recommended</div>)
                return;
            }
        }

        let recObject = {
            enricheduserbookAID: bookItem.userbookID,
            enricheduserbookBID: selectedBook.userbookID,
            note: noteTracker,
            reciprical : reciprical
        }

        props.setNewRecommendation(recObject);

    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <div className={styles.bookB}>
                    <input type="text"
                        className={styles.input}
                        placeholder="Recommended Book"
                        value={bookBTracker}
                        onChange={changeBookB} />

                    <Textarea className={styles.input} key="note input"
                        autoSize={{ minRows: 10 }}
                        type="text"
                        placeholder="Recommendation Note"
                        value={noteTracker}
                        onChange={changeNote} />
                    <div className={styles.recipricalContainer}>
                        <input type="checkbox" id="reciprical" name="reciprical" onChange={changeReciprical}/>
                        <label htmlFor="reciprical" className={styles.label}>Reciprical recommendation</label>
                    </div>

                </div>
                <div className={styles.suggestions}>
                    {
                        selectedBook !== null ?
                            <div className={styles.selectedBook}>
                                <SelectedBookSuggestion bookItem={selectedBook} />
                                <img className={styles.removeSelection} alt="remove selected book button button" onClick={removeSelection} />
                            </div>
                            :
                            <div>{suggestions}</div>
                    }
                </div>

            </div>

            {errorMessage}

            <div className={styles.buttons}>
                <button type="submit" className={styles.cancelButton} onClick={() => props.setIsAdding(false)}>Cancel</button>
                <button type="submit" className={styles.submitButton} onClick={() => submitRec()}>Submit</button>
            </div>
        </section>
    )
}