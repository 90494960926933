import { Footer } from "../comonents/Footer/Footer";
import { LibraryList } from "../comonents/LibraryList/LibraryList";
import styles from "./pages.module.css";

export default function Library () {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <LibraryList /> 
            </div>
            <Footer />
        </div>
    );
}