import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./SearchBook.module.css";
import { Cover } from "../../../Cover/Cover";
import { Author } from "../../../Author/Author";


export const SearchBook = (props) => {

    // props:
    //
    // bookItem - object containing book information
    // active - bool, indicates if book is selected or not

    // ----------------------------------------------------- PRE-RENDER
    
    // ----------------------------------------------------- PRE-RENDER
    const bookPath = "/book/" + props.bookItem.bookID;


    // ----------------------------------------------------- RENDER
    return (
        
        <section className={props.active ? styles.containerActive : styles.container}>
            <div className={styles.content}>
                <Cover bookItem = {props.bookItem} size="S" loc="searchBook"/>
                <Link to={bookPath} className={styles.link}> 
                <div className={styles.bookInfo}>
                    <h2 className={styles.bookTitle}>{props.bookItem.title}</h2>
                    {props.bookItem.series != null ?
                    <div>
                        <h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3>
                    </div> :
                    null}
                    <Author authorsList={props.bookItem.authors} size={0} link={false}/>
                </div>
                </Link>
            </div>
        </section>
        
    );
}