
import React, { useState, useEffect, useRef } from "react";
import styles from "./Dropdown.module.css";

import { DropdownButton } from "./DropdownButton";
import { DropdownContent } from "./DropdownContent";


export const Dropdown = (props) => {

    // props:
    //
    // buttonText - string, the value desplayed in dropdown 
    // content - list of DropdownItems 
    // fontSize - font size of button text

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const dropdownRef = useRef();
    const contentRef = useRef();

    useEffect(() => { //closes dropdown when somewhere else is clicked
        const handler = (event) => {
            //closes if somewhere else is clicked
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
            //closes if content is clicked
            if (contentRef.current && contentRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("click", handler);

        return () => { //cleans up listener
            document.removeEventListener("click", handler)
        }

    }, [dropdownRef, contentRef]);

    return (
        <div className={styles.container} ref={dropdownRef}>
            <DropdownButton open={isOpen} 
                            toggle={toggleDropdown} 
                            buttonText={props.buttonText}
                            fontSize={props.fontSize}>
            </DropdownButton>

            <div ref={contentRef}>
                <DropdownContent open= {isOpen} 
                                 content={props.content}>
                </DropdownContent>
            </div>
        </div>
    );
}