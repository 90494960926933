import React from "react";

import styles from "./FieldsBox.module.css";

import { StarRating } from "../StarRating/StarRating";
import { TagEditor } from "../TagEditor/TagEditor";
import { StatusEditor } from "../StatusEditor/StatusEditor";
import { Readings } from "../Readings/Readings";
import { useBook, useBookUpdate } from "../../contexts/BookContext";
import { ProgressUpdater } from "../ProgressUpdater/ProgressUpdater";

export const FieldsBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const bookItem = useBook();
    const bookUpdater = useBookUpdate();

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <h3 className={styles.infoTitle}>Status</h3>
            <StatusEditor />

            {bookItem.status === "currentlyReading" ?
                <div>
                    <h3 className={styles.infoTitle}>Progress</h3>
                    <ProgressUpdater bookItem={bookItem} bookUpdater = {bookUpdater}/>
                </div>
                :
                null
            }

            <h3 className={styles.infoTitle}>Rating</h3>
            <StarRating />

            <Readings />

            <TagEditor />
        </div>
    );
}
