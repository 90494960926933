import React, { useState, useEffect, useRef } from "react";
import styles from "./SelectedBookSuggestion.module.css";

import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

import { Link } from "react-router-dom";

export const SelectedBookSuggestion = (props) => {

    // props:
    // 
    // bookItem - object containing book infromation

    // ----------------------------------------------------- STATE AND HOOKS

    const [firstWordsOfTitle, setFirstWordsOfTitle] = useState(""); // for star in title
    const [lastWordOfTitle, setLastWordOfTitle] = useState("");


    const bookPath = "/book/" + props.bookItem.bookID;
    const seriesPath = "/series/" + props.bookItem.series?.id;

    useEffect(()=>{
        if (props.bookItem && props.bookItem.authors) { 
            processTitle();
        }
    },[props.bookItem])


    // ----------------------------------------------------- PRE-RENDER

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = props.bookItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "

        if (props.bookItem.favorited) {
            lastWord = [lastWord , <img key="star" className={styles.starIconFilled} alt="Favorite star" />];
        }
        else {
            lastWord = [lastWord];
        }

        setFirstWordsOfTitle(firstWords);
        setLastWordOfTitle(lastWord);
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Cover bookItem={props.bookItem} size={window.screen.width > 500 ? "M" : "S"} loc="recommendationBook" />
                <div className={styles.bookInfo}>
                    <Link to={bookPath} className={styles.link}>
                        <div>
                            <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                            <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                        </div>
                    </Link>
                    {props.bookItem.series != null ?
                        <div>
                            <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                        </div> :
                        null
                    }
                    <Author authorsList={props.bookItem.authors} size={1} link={true}/>
                </div>
            </div>
        </div>
    )
}