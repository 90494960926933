import React, { useEffect, useState } from "react";
import styles from "./Author.module.css";
import { Link } from "react-router-dom";

export const Author = (props) => {

    // props:
    // 
    // authorsList - array of objects containing author information
    // size - 0 = XS,  1 = S or  2 = L
    // link - indicates if author should link to author page true or false

    // ----------------------------------------------------- STATE AND HOOKS
    const [authors, setAuthors] = useState([]);

    let sizeOptions = [
        styles.contentXS,
        styles.contentS,
        styles.contentL
    ]

    let andSizeOptions = [
        styles.andXS,
        styles.andS,
        styles.andL
    ]

    useEffect(() => {
        // creates author object on first render and if authorsList changes
        formatAuthors();
    },[props.authorsList])

    // ----------------------------------------------------- PRE-RENDER

    function formatAuthors() {
        let temp = []
        for (let i = 0; i < props.authorsList.length; i++) {
            let authorPath = "/author/" + props.authorsList[i].id;
            let authorDisplayString = props.authorsList[i].last + ", " + props.authorsList[i].first;
            
            if (props.link) {
                // makes linked author
                temp.push (
                    <Link to={authorPath} className={sizeOptions[props.size]} key={props.authorsList[i].id}>
                        {authorDisplayString}{ i+1 < props.authorsList.length ? " &" : null}
                    </Link>
                ) 
            } else {
                // makes un-linked author
                temp.push (
                    <div className={sizeOptions[props.size]} key={props.authorsList[i].id}>
                        {authorDisplayString} { i+1 < props.authorsList.length ? " &" : null} 
                    </div>
                ) 
            }
    
            // // adds and if necessary
            // if (i+1 < props.authorsList.length) {
            //     temp.push (
            //         <div className={andSizeOptions[props.size]} key={"and"+i}>
            //             &
            //         </div>
            //     )
            // }
        }  
        // sets to state var
        setAuthors(temp)
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {authors}
        </section>
    )

    
}