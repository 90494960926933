import React from "react";
import styles from "./ProfileDropdownContent.module.css";

import { ProfileDropdownItem } from "./ProfileDropdownItem"
import { Link, useNavigate } from "react-router-dom";



export const ProfileDropdownContent = (props) => {

    // props:
    // 
    // isOpen - state var if dropdown content should be displayed

    // ----------------------------------------------------- STATES AND HOOKS

    let token = localStorage.getItem("token");
    var tokenPeices = [];
    var idAndRole = [];
    var decodedUserID = "";

    const navigate = useNavigate();
    
    if (token != null) {
        tokenPeices = token.split(".");
        idAndRole = atob(tokenPeices[1]);
        decodedUserID = idAndRole.split(",")[0];
    }

    // ----------------------------------------------------- PRE-RENDER

    function signOut() {
        // clears token
        localStorage.removeItem("token");
        navigate("/")
        window.location.reload();
    }

    const content = [
        <Link to={`/account/${decodedUserID}`} className={styles.link} key={"profile"}><ProfileDropdownItem displayValue={"Account"} isSignOut={false}/></Link>,
        <div key={"signOut"}><ProfileDropdownItem displayValue={"Sign Out"} isSignOut={true} onClick={signOut}/></div>
    ]
    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            {props.isOpen ? <div className={styles.contentOpen}>{content}</div> : null}
        </section>
    )
}