import React from "react";
import styles from "./SeriesSuggestion.module.css";

export const SeriesSuggestion = (props) => {

    // props:
    // 
    // seriesItem - object containing series information
    // setSelectedSeries - state var setter to tell parent series was selcted

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    // sorts books in series by series number
    let booksContainedIn = []
    if (props.seriesItem.booksInSeries != null) {
        let sortedSeriesBooks = props.seriesItem.booksInSeries.sort(function (a, b) {
            if (a.seriesNum > b.seriesNum) {
                return 1;
            }
            if (a.seriesNum < b.seriesNum) {
                return -1;
            }
            return 0;
        });

        // makes series objects

        for (let i = 0; i < sortedSeriesBooks.length; i++) {
            booksContainedIn.push(
                <div className={styles.booksInSeries} key={"group" + i}>
                    <h5 key={"num" + i} className={styles.seriesNum}>{sortedSeriesBooks[i].seriesNum}.</h5>
                    <h5 key={i} className={styles.bookTitle}>{sortedSeriesBooks[i].title}</h5>
                </div>
            )
        }
    }
    function handleClick() {
        // sends selection back to parent
        if (props.setSelectedSeries) {
            props.setSelectedSeries(props.seriesItem)
        }
 
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={handleClick}>
            <div className={styles.content}>
                <h2 className={styles.seriesName}>{props.seriesItem.name}</h2>
                {booksContainedIn}
            </div>
        </section>
    )
}