import React from "react";
import styles from "./PageSelector.module.css";

export const PageSelector = (props) => {

    //props:
    //
    // totalPages - total number of page options
    // switchPage - function to re-render page, sends back new page number
    // selectedPage - currently selected page

    // ----------------------------------------------------- PRE-RENDER

    let pageList = [];

    if (window.screen.width > 500) {
        // desktop
        for (let i = 1; i < props.totalPages + 1; i++) {
            pageList.push(<button className={i == props.selectedPage ? styles.pageButtonActive : styles.pageButton}
                key={i}
                onClick={() => { props.switchPage(i) }}>
                {i}
            </button>
            );
        }
    } else {
        // mobile

        // prev button if needed
        if (props.selectedPage > 1) {
            pageList.push(<button className={styles.carrotBackButton} onClick={ () => props.switchPage(props.selectedPage - 1) } key="topDec"> <img className={styles.carrot} src="/assets/images/backIcon.svg" /> </button>)
        }

        // current page
        pageList.push(<div className={styles.mobilePage}>{props.selectedPage}</div>)

        // next button, if needed
        if (props.selectedPage < props.totalPages) {
            pageList.push(<button className={styles.carrotNextButton} onClick={ () => props.switchPage(props.selectedPage + 1) } key="topInc"> <img className={styles.carrot} src="/assets/images/nextIcon.svg" /> </button>)
        }
    }




    // } else {
    //     pageList.push(
    //         <button className={1 == props.selectedPage ? styles.pageButtonActive : styles.pageButton}
    //             key={1}
    //             onClick={() => { props.switchPage(1) }}>
    //             {1}
    //         </button>
    //     )
    //     for (let i = 2; i < props.totalPages; i++) {
    //         if (i === props.selectedPage - 1 || i === props.selectedPage || i === props.selectedPage + 1 || i === props.totalPages + 1) {
    //             pageList.push(<button className={ i == props.selectedPage ? styles.pageButtonActive : styles.pageButton} 
    //                               key={i} 
    //                               onClick={ () => { props.switchPage(i) } }>
    //                               {i} 
    //                       </button>
    //             );
    //         }

    //     }
    // }


    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            {pageList}
        </div>
    );
}