import React from "react";
import styles from "./Footer.module.css";

export const Footer = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h4 className={styles.title}>Earmarked</h4>
                <h5>Contact: info@earmarked.app</h5>
            </div>
        </section>
    )
}