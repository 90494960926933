import React from "react";
import styles from "./GoogleBook.module.css";

export const GoogleBook = (props) => {

    // props:
    // 
    // googleBookItem - object containing response from google book API
    // setSelectedOption - state var setter to send click back to parent

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    var isbn = ""
    if (props.googleBookItem.industryIdentifiers != null) {
        for (let i = 0; i < props.googleBookItem.industryIdentifiers.length; i++) {
            if (props.googleBookItem.industryIdentifiers[i].type == "ISBN_13") {
                isbn = props.googleBookItem.industryIdentifiers[i].identifier;
            }
        }
    }

    let coverPath = `https://covers.openlibrary.org/b/isbn/${isbn}-M.jpg`;
    
    function handleClick() {
        // sends click back to parent
        props.setSelectedOption(props.googleBookItem);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={handleClick}>
            <div className={styles.content}>
            {isbn != 0 ? 
                    <img className={styles.bookCover} src={coverPath} /> 
                    :
                    <div className={styles.noCover}> No Cover Avaliable </div>
                }
                <div className={styles.bookInfo}>
                    <div className={styles.bookTitle}>{props.googleBookItem?.title}</div>
                    {props.googleBookItem.authors ? <div className={styles.bookAuthor}>{props.googleBookItem?.authors[0]}</div> : null} 
                </div>
            </div>

        </section>
    )
}